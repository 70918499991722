import { observer } from 'mobx-react'
import React, { useContext, useEffect, useState } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { StoreContext } from '../../components/App'
import { InnerAppContent, InnerWizardLayout } from '../../styles/generic.styles'
import { OnboardingWizard } from '../../components/OnboardingWizard/OnboardingWizard'
import { LeftSideNav } from '../../components/LeftSideNav/LeftSideNav'
import { getPath } from '../../methods/getPath'
import LogoEn from '../../assets/logo_en.svg'
import LogoNl from '../../assets/logo_nl.svg'

const path = getPath()

const enrollCompanyStepsData = [
  {
    name: 'enrollCompanyPage.personalData',
    id: 'identity-intro',
    status: '',
    touched: false,
    nav: false,
    steps: [
      {
        name: 'enrollCompanyPage.identityVerification.title',
        id: 'identity-verification',
        status: '',
        touched: false,
        nav: false,
      },
      {
        name: 'enrollCompanyPage.addressVerification.title',
        id: 'address-verification',
        status: '',
        touched: false,
        nav: false,
      },
    ],
  },
  ...(path.includes('enroll-company')
    ? [
        {
          name: 'enrollCompanyPage.companyData.title',
          id: 'company-intro',
          status: '',
          touched: false,
          nav: true,
          steps: [
            {
              name: 'enrollCompanyPage.basicInformation',
              id: 'company-data',
              status: '',
              touched: false,
              nav: true,
            },
            {
              name: 'enrollCompanyPage.businessAddress.title',
              id: 'business-address',
              status: '',
              touched: false,
              nav: true,
            },
            {
              name: 'enrollCompanyPage.directors.title',
              id: 'directors',
              status: '',
              touched: false,
              nav: true,
            },
            {
              name: 'enrollCompanyPage.ubos.title',
              id: 'ubos',
              status: '',
              touched: false,
              nav: true,
            },
          ],
        },
      ]
    : []),
  {
    name: 'enrollCompanyPage.summary.title',
    status: '',
    id: 'summary',
    touched: false,
    nav: true,
  },
]

export const EnrollCompanyPage = observer(() => {
  const location = useLocation()
  const navigate = useNavigate()
  const store = useContext(StoreContext)
  const { theme } = store.AppState
  const { language } = store.TranslationsState
  const { enrollCompanyPage: trans } = store.TranslationsState.translations

  const [currentStep, setCurrentStep] = useState('identity-intro')
  const [data, setData] = useState(enrollCompanyStepsData)
  const [logo, setLogo] = useState(theme.logo || null)

  function handleStepChange() {
    let currentCatIndex = -1
    let currentStepIndex = -1

    data.forEach((category, catIndex) => {
      if (category.id === currentStep) {
        currentCatIndex = catIndex
        currentStepIndex = -1
        category.touched = true
      }

      if (category.steps && category.steps.length) {
        category.steps.forEach((step, stepIndex) => {
          if (step.id === currentStep) {
            currentCatIndex = catIndex
            currentStepIndex = stepIndex
            category.touched = true
            step.touched = true
          }
        })
      }
    })

    const output = JSON.parse(JSON.stringify(data)).map(
      (category, catIndex) => {
        if (!category.touched) {
          if (catIndex === currentCatIndex) {
            category.status = 'active'
          } else if (catIndex < currentCatIndex) {
            category.status = 'done'
          } else {
            category.status = ''
          }
        } else {
          if (catIndex === currentCatIndex) {
            category.status = 'active'
          } else {
            category.status = 'done'
          }
        }

        if (category.steps && category.steps.length) {
          category.steps = category.steps.map((step, stepIndex) => {
            if (!step.touched) {
              if (currentCatIndex === catIndex) {
                if (stepIndex === currentStepIndex) {
                  step.status = 'active'
                } else if (stepIndex < currentStepIndex) {
                  step.status = 'done'
                } else {
                  step.status = ''
                }
              } else {
                if (catIndex < currentCatIndex) {
                  step.status = 'done'
                } else if (catIndex > currentCatIndex) {
                  step.status = ''
                }
              }
            } else {
              if (
                currentCatIndex === catIndex &&
                stepIndex === currentStepIndex
              ) {
                step.status = 'active'
              } else {
                step.status = 'done'
              }
            }

            return step
          })
        }

        return category
      }
    )

    // WORKAROUND FOR ADDRESS VERIFICATION POLLING
    if (
      currentCatIndex === -1 &&
      currentStep === 'address-verification-polling'
    ) {
      output[0].steps[0].status = 'done'
      output[0].steps[1].status = 'active'
    }

    setData(output)
  }

  function changeStep(stepId: string) {
    navigate(`/${path}/${stepId}`)
  }

  useEffect(() => {
    setCurrentStep(location.pathname.split('/')[2])
  }, [location])

  useEffect(() => {
    handleStepChange()
  }, [currentStep])

  useEffect(() => {
    if (!theme.logo) {
      switch (language) {
        case 'en':
          setLogo(LogoEn)
          break
        case 'nl':
          setLogo(LogoNl)
          break
        default:
          setLogo(null)
      }
    }
  }, [language])

  return (
    <div>
      <InnerWizardLayout>
        <LeftSideNav
          header={
            logo ? <img src={logo} alt={trans.navHeader} /> : trans.navHeader
          }
          {...theme.leftSideNav}
          width="480px"
        >
          <OnboardingWizard
            {...theme.onboardingWizard}
            data={data}
            changeStep={changeStep}
          />
        </LeftSideNav>
        <InnerAppContent padding="0 84px 40px">
          <Outlet />
        </InnerAppContent>
      </InnerWizardLayout>
    </div>
  )
})
