import React, { FC } from 'react'
import Select, { MultiValue } from 'react-select'

export interface SelectItem {
  value: string
  label: string
}

type SelectValue = SelectItem | MultiValue<SelectItem>

interface SelectProps {
  name?: string
  value?: SelectValue
  onChange?: (newValue: SelectValue) => void
  options?: SelectItem[]
  isMulti?: boolean
  isDisabled?: boolean
}

export const SimpleSelect: FC<SelectProps> = ({
  name,
  value,
  onChange,
  options,
  isMulti,
  isDisabled,
}) => {
  return (
    <Select
      name={name || 'select'}
      styles={{
        container: (base) => ({
          ...base,
          width: '100% !important',
          fontSize: '14px',
        }),
        menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
        menu: (provided) => ({ ...provided, zIndex: 9999, fontSize: '14px' }),
      }}
      value={value}
      onChange={(newValue) => onChange(newValue)}
      options={options}
      isMulti={!!isMulti}
      isDisabled={!!isDisabled}
      menuPortalTarget={document.body}
      menuPosition={'fixed'}
      classNamePrefix="simple-select"
    />
  )
}
