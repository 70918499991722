import React from 'react'
import { Container } from './PosLogo.styles'
import posLogo from '../../assets/posLogo.webp'

interface PosLogoProps {
  color?: string
  bgColor?: string
  width?: string
  padding?: string
}

export const PosLogo = ({ color, bgColor, width, padding }: PosLogoProps) => {
  return (
    <Container
      width={width ? width : '149px'}
      padding={padding ? padding : '0'}
    >
      <img src={posLogo} />
    </Container>
  )
}
