import { observer } from 'mobx-react'
import React, { useContext } from 'react'
import { StoreContext } from '../../components/App'
import { LoginPromo } from '../../components/LoginPromo/LoginPromo'
import { SignicatLogo } from '../../components/SignicatLogo/SignicatLogo'
import {
  AppDownloadGrid,
  Column,
  ColumnContent,
  ColumnFlexLine,
  ColumnHeading,
  ColumnSubtitle,
  FullScreen,
  NoShow,
  Page,
  PosColumnContent,
  PosHeader,
  PosLoginHeader,
  PosLoginSubtitle,
  PosLogoWrapper,
  PosPhoneBanner,
  PosSubtitle,
} from './LoginPage.styles'
import { PosLogo } from '../../components/PosLogo/PosLogo'
import MyPosBannerImg from '../../assets/posLoginBanner.png'
import MyPosAppStoreDownload from '../../assets/PosAppStoreDownload.png'
import MyPosAndroidDownload from '../../assets/PosAndroidDownload.png'
import MyPosAppGalleryDownload from '../../assets/PosAppGalleryDownload.png'
import { Button } from '../../components/Button/Button'

export const LoginPage = observer(() => {
  const store = useContext(StoreContext)
  const { theme } = store.AppState

  function handleLogin() {
    window.location.href =
      'https://merchant-poc-2577551f9ce2.herokuapp.com/auth/signin'
  }

  const renderLoginPage = () => {
    switch (theme.globals.name) {
      case 'pos':
        return (
          <FullScreen>
            <Column
              background="radial-gradient(53.37% 53.37% at 26.39% 46.63%, #8CC0E5 0%, #4D92CD 100%)"
              first
            >
              <PosColumnContent>
                <PosLogoWrapper>
                  <PosLogo width="64px" />
                </PosLogoWrapper>

                <PosPhoneBanner src={MyPosBannerImg} />
                <PosHeader>myPOS app - go mobile!</PosHeader>
                <PosSubtitle>
                  Access your myPOS account to manage payments and POS devices,
                  keep track of your earnings and balances wherever you are.
                </PosSubtitle>

                <AppDownloadGrid>
                  <img src={MyPosAppStoreDownload} />
                  <img src={MyPosAndroidDownload} />
                  <img src={MyPosAppGalleryDownload} />
                </AppDownloadGrid>
              </PosColumnContent>
            </Column>
            <Column>
              <PosColumnContent marginTop="39px" justifyContent="center">
                <NoShow>
                  <PosLogo width="105px" />
                </NoShow>
                <ColumnHeading>
                  <PosLoginHeader>Welcome back!</PosLoginHeader>
                  <PosLoginSubtitle>
                    Please proceed to create a secure session using your
                    company’s SSO
                  </PosLoginSubtitle>
                </ColumnHeading>

                <Button
                  {...theme.proceedButton}
                  onClick={() => handleLogin()}
                  width="350px"
                  height="50px"
                  widthMobile="300px"
                  heightMobile="50px"
                >
                  Create session
                </Button>

                <NoShow>
                  <ColumnFlexLine>
                    <LoginPromo />
                  </ColumnFlexLine>
                </NoShow>
              </PosColumnContent>
            </Column>
          </FullScreen>
        )

      default:
        return (
          <FullScreen>
            <Column background="#F9F9FB" first>
              <ColumnContent>
                <SignicatLogo width="105px" color="#2A0062" bgColor="#fff" />
                <ColumnHeading>
                  <h1>Welcome back!</h1>
                  <ColumnSubtitle marginBottom="104px">
                    {`Please create a secure session before identifying others :-)`}
                  </ColumnSubtitle>
                </ColumnHeading>

                <ColumnFlexLine>
                  <LoginPromo />
                </ColumnFlexLine>
              </ColumnContent>
            </Column>
            <Column>
              <ColumnContent marginTop="39px">
                <NoShow>
                  <SignicatLogo width="105px" color="#2A0062" bgColor="#fff" />
                </NoShow>
                <ColumnHeading>
                  <h1>Login</h1>
                  <ColumnSubtitle marginBottom="84px">
                    {`Please proceed to create a secuse session using your Signicat's
                Microsoft account.`}
                  </ColumnSubtitle>
                </ColumnHeading>

                <Button
                  {...theme.proceedButton}
                  onClick={() => handleLogin()}
                  width="100%"
                >
                  Create session
                </Button>

                <NoShow>
                  <ColumnFlexLine>
                    <LoginPromo />
                  </ColumnFlexLine>
                </NoShow>
              </ColumnContent>
            </Column>
          </FullScreen>
        )
    }
  }

  return <Page>{renderLoginPage()}</Page>
})
