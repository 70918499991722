import React, { FC } from 'react'
import { StyledCascader } from './GicsSelect.styles'
import { gicsDataSet } from '../../constants/gicsSorted'

export interface GicsSelectItem {
  value?: string
  label?: string
  indentCount?: number
}

export type GicsSelectValue = Array<string>

interface GicsSelectProps {
  name?: string
  value?: GicsSelectValue
  onChange?: (newValue: GicsSelectValue) => void
  disabled?: boolean
}

export const GicsSelect: FC<GicsSelectProps> = ({
  value,
  onChange,
  disabled,
}) => {
  return (
    <StyledCascader
      className="gics-select"
      options={gicsDataSet.options}
      value={value}
      onChange={onChange}
      disabled={disabled}
      changeOnSelect
    />
  )
}
