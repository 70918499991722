import { createGlobalStyle } from 'styled-components'

interface GlobalStyleProps {
  bgColor?: string
  fontFamily?: string
  checkboxColor?: string
  checkboxColorActive?: string
  container?: {
    bgColor?: string
  }
  linkColor?: string
  color?: string
}

export const GlobalStyle = createGlobalStyle<GlobalStyleProps>`
  body {
    font-family: 'Inter';
    background-color: ${(props) => (props.bgColor ? props.bgColor : 'gray')};
    padding: 0;
    margin: 0;
    font-family: ${(props) => (props.fontFamily ? props.fontFamily : '')};

    @media (max-width: 980px) {
      background-color: #ffffff;
    }

    button {
      justify-content: center;
      font-family: 'Inter';
    }

    /* this is only checkbox styles, move it */
    input[type="checkbox"] {
      padding: 0;
      height: initial;
      width: initial;
      margin-bottom: 0;
      display: none;
      cursor: pointer;
    }

    .label-text {
      position: relative;
      display: flex;
    }

    .label-text:before {
      width: 20px;
      height: 20px;
      content:'';
      -webkit-appearance: none;
      background-color: transparent;
      border: ${(props) =>
        props.checkboxColor
          ? `${props.checkboxColor} 2px solid !important`
          : 'gray 2px solid !important'};
      padding: 8px;
      display: inline-block;
      position: relative;
      vertical-align: middle;
      cursor: pointer;
      margin-right: 10px;
      top: 0px;
      box-sizing: border-box;
    }

    input:checked + .label-text:before {
      background-color: ${(props) =>
        props.checkboxColorActive ? props.checkboxColorActive : 'black'};
      border: ${(props) =>
        props.checkboxColorActive
          ? `${props.checkboxColorActive} 2px solid !important`
          : 'gray 2px solid !important'};
    }

    input:checked + .label-text:after {
      content: '';
      display: block;
      position: absolute;
      top: 2px !important;
      left: 6.5px;
      width: 6px;
      height: 9.5px;
      border: solid
        ${(props) =>
          props.container.bgColor ? `${props.container.bgColor}` : 'white'};
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }
  }
`
