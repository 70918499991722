import { observer } from 'mobx-react'
import React, { useContext, useState } from 'react'
import { AddMerchantForm } from '../../components/AddMerchantForm/AddMerchantForm'
import { StoreContext } from '../../components/App'
import { TopBar } from '../../components/TopBar/TopBar'
import { InnerAppContent, InnerAppLayout } from '../../styles/generic.styles'
import { MerchantsTable } from './MerchantsTable/MerchantsTable'
import { LeftSideNav } from '../../components/LeftSideNav/LeftSideNav'
import { Button } from '../../components/Button/Button'
import { SlidingPanel } from '../../components/SlidingPanel/SlidingPanel'
import { NavMenu } from '../../components/NavMenu/NavMenu'
import { PageHeading } from '../../components/PageHeading/PageHeading'

export const MerchantsPage = observer(() => {
  const store = useContext(StoreContext)
  const { theme } = store.AppState

  const [showAddMerchant, setShowAddMerchant] = useState(false)

  function handleAddMerchant(show: boolean) {
    setShowAddMerchant(show)
  }

  return (
    <div>
      <SlidingPanel
        visibility={showAddMerchant}
        handleVisibility={handleAddMerchant}
      >
        <AddMerchantForm handleOnSend={() => setShowAddMerchant(false)} />
      </SlidingPanel>
      <TopBar />
      <InnerAppLayout>
        <LeftSideNav header="Dashboard" {...theme.leftSideNav}>
          <NavMenu
            {...theme.navMenu}
            items={[
              {
                name: 'dashboard',
                label: 'Dashboard',
                urls: ['/', '/merchants'],
              },
              // {
              //   name: 'onboarding',
              //   label: 'Onboarding Mock',
              //   urls: ['/onboarding'],
              // },
            ]}
          />
        </LeftSideNav>
        <InnerAppContent>
          <PageHeading
            title="Merchants"
            subtitle="Track and manage merchants"
            {...theme.pageHeading}
          >
            <Button
              {...theme.proceedButton}
              onClick={() => handleAddMerchant(true)}
              minWidth="184px"
            >
              Add new merchant
            </Button>
          </PageHeading>
          <MerchantsTable />
        </InnerAppContent>
      </InnerAppLayout>
    </div>
  )
})
