import React, { FC } from 'react'
import Select, {
  MultiValue,
  SingleValueProps,
  OptionProps,
  components,
} from 'react-select'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { countryList } from './countryList'
import { CountryFlag, CountryItemInner } from './CountrySelect.styles'

const { Option, SingleValue } = components

export interface CountrySelectItem {
  value?: string
  label?: string
  icon?: string
}

type CountrySelectValue = CountrySelectItem | MultiValue<CountrySelectItem>

interface CountrySelectProps {
  name?: string
  value?: CountrySelectValue
  onChange?: (newValue: CountrySelectValue) => void
  options?: CountrySelectItem[]
  isMulti?: boolean
  disabled?: boolean
}

const CountryOption: FC<OptionProps<CountrySelectItem>> = (props) => {
  const { data } = props

  return (
    <Option {...props}>
      <CountryItemInner>
        {data.icon && (
          <CountryFlag>
            <LazyLoadImage alt={props.data.value} src={props.data.icon} />
          </CountryFlag>
        )}
        <span>{data.label}</span>
      </CountryItemInner>
    </Option>
  )
}

const CountrySingleValue: FC<SingleValueProps<CountrySelectItem>> = (props) => {
  const { data } = props

  return (
    <SingleValue {...props}>
      <CountryItemInner>
        {data.icon && (
          <CountryFlag>
            <LazyLoadImage alt={props.data.value} src={props.data.icon} />
          </CountryFlag>
        )}
        <span>{data.label}</span>
      </CountryItemInner>
    </SingleValue>
  )
}

export const EMPTY_COUNTRY: CountrySelectItem = {
  value: '',
  label: 'Select a country',
}

export const CountrySelect: FC<CountrySelectProps> = ({
  name,
  value,
  onChange,
  options,
  isMulti,
  disabled,
}) => {
  return (
    <Select
      name={name || 'country-select'}
      styles={{
        container: (base) => ({
          ...base,
          width: '100% !important',
        }),
        menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
        menu: (provided) => ({ ...provided, zIndex: 9999 }),
      }}
      value={value}
      onChange={(newValue) => onChange(newValue)}
      options={options || countryList}
      isMulti={!!isMulti}
      isDisabled={!!disabled}
      components={{
        Option: CountryOption,
        SingleValue: CountrySingleValue,
      }}
      menuPortalTarget={document.body}
      menuPosition={'fixed'}
      classNamePrefix="country-select"
    />
  )
}
