import { countryList } from './countryList'
import { EMPTY_COUNTRY } from './CountrySelect'

export const findCountry = (query: string) => {
  const searchQuery = query.toLowerCase()

  const foundCountry = countryList.find(
    (country) =>
      country.label.toLowerCase().includes(searchQuery) ||
      country.value.toLowerCase().includes(searchQuery)
  )

  return foundCountry?.label ? foundCountry : EMPTY_COUNTRY
}

export const findCountryByValue = (query: string) => {
  const searchQuery = query.toLowerCase()

  const foundCountry = countryList.find((country) =>
    country.value.toLowerCase().includes(searchQuery)
  )

  return foundCountry?.label ? foundCountry : EMPTY_COUNTRY
}
