import React from 'react'
import styled from 'styled-components'

const Image = styled.div`
  svg {
    width: 100%;
  }
`

export const LoginPromo = () => {
  return (
    <Image>
      <svg
        width="529"
        height="416"
        viewBox="0 0 529 416"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M0 0H333.481V333.481H0V0Z" fill="#2A0062" />
        <path
          d="M363.174 415.708C454.758 415.708 529.001 341.465 529.001 249.881C529.001 158.298 454.758 84.0547 363.174 84.0547C271.591 84.0547 197.348 158.298 197.348 249.881C197.348 341.465 271.591 415.708 363.174 415.708Z"
          fill="#41FFD1"
        />
        <path
          d="M222.71 399.996C218.423 386.853 213.193 374.024 209.865 360.599C220.446 344.289 230.865 334.32 265.065 334.32C272.568 334.32 280.102 334.707 287.355 334.707C290.949 334.707 295.881 334.57 300.248 334.57C337.567 334.57 351.508 350.405 361.541 399.255"
          fill="#41FFD1"
        />
        <path
          d="M222.71 399.996C218.423 386.853 213.193 374.024 209.865 360.599C220.446 344.289 230.865 334.32 265.065 334.32C272.568 334.32 280.102 334.707 287.355 334.707C290.949 334.707 295.881 334.57 300.248 334.57C337.567 334.57 351.508 350.405 361.541 399.255"
          stroke="#300161"
          strokeWidth="3.52802"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M230.373 261.318C229.091 265.767 227.149 271.996 227.149 277.161C227.149 285.332 233.596 292.714 237.786 292.714C242.468 292.714 246.651 283.302 246.651 277.121C246.706 275.56 246.365 274.01 245.66 272.616C242.444 266.919 235.28 263.873 230.373 261.318Z"
          fill="#300161"
          stroke="#300161"
          strokeWidth="3.52802"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M319.744 293.554C320.196 293.554 320.647 293.554 321.09 293.554C331.163 293.554 338.101 284.488 338.101 276.711C338.101 272.569 334.411 270.934 330.792 270.934C321.864 270.934 317.979 273.077 317.979 285.495C317.979 288.388 318.286 291.257 319.744 293.554Z"
          fill="#300161"
          stroke="#300161"
          strokeWidth="3.52802"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M238.747 249.706C236.805 243.396 233.509 236.934 227.731 236.934C222.526 236.934 218.263 241.092 218.263 249.271C218.263 255.395 228.457 281.335 234.452 281.335C236.555 281.335 238.481 279.957 239.98 278.16"
          fill="#41FFD1"
        />
        <path
          d="M238.747 249.706C236.805 243.396 233.509 236.934 227.731 236.934C222.526 236.934 218.263 241.092 218.263 249.271C218.263 255.395 228.457 281.335 234.452 281.335C236.555 281.335 238.481 279.957 239.98 278.16"
          stroke="#300161"
          strokeWidth="3.52802"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M324.867 251.574C329.63 245.442 334.135 237.367 340.815 237.367C344.965 237.367 347.262 240.961 347.262 245.426C347.262 250.422 344.264 255.555 342.967 260.068C341.081 266.659 339.389 281.825 331.048 281.825C326.334 281.825 325.222 279.255 322.716 274.984"
          fill="#41FFD1"
        />
        <path
          d="M324.867 251.574C329.63 245.442 334.135 237.367 340.815 237.367C344.965 237.367 347.262 240.961 347.262 245.426C347.262 250.422 344.264 255.555 342.967 260.068C341.081 266.659 339.389 281.825 331.048 281.825C326.334 281.825 325.222 279.255 322.716 274.984"
          stroke="#300161"
          strokeWidth="3.52802"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M340.245 250.246C340.04 249.616 339.646 249.065 339.117 248.667C338.588 248.269 337.949 248.044 337.287 248.021C331.695 248.021 327.544 257.974 325.095 263.067"
          stroke="#300161"
          strokeWidth="3.52802"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M224.079 251.054C224.542 250.101 225.275 249.305 226.186 248.763C227.096 248.222 228.146 247.959 229.204 248.008C233.467 248.008 236.836 254.406 239.116 259.612"
          stroke="#300161"
          strokeWidth="3.52802"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M331.91 244.175C332.493 240.08 332.789 235.95 332.796 231.814C332.796 199.516 321.966 184.156 282.141 184.156C256.701 184.156 234.089 203.416 234.089 236.536C234.089 280.301 240.536 327.636 284.736 327.636C324.125 327.636 327.446 292.638 330.758 253.233C331.007 250.187 331.668 247.205 331.91 244.175Z"
          fill="#41FFD1"
          stroke="#300161"
          strokeWidth="3.52802"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M293.537 252.977C293.481 254.105 293.44 255.201 293.44 256.281C293.44 265.556 297.212 268.288 297.212 273.703C297.212 279.295 291.571 282.285 286.438 282.285C282.102 282.285 278.556 280.198 276.453 274.323"
          stroke="#300161"
          strokeWidth="3.52802"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M276.581 291.799C279.297 295.514 282.657 297.657 286.009 297.657C288.773 297.657 291.529 296.191 293.899 292.935"
          stroke="#300161"
          strokeWidth="3.52802"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M300.642 239.297C302.384 238.729 304.201 238.425 306.033 238.394C307.695 238.364 309.342 238.71 310.851 239.408C312.36 240.106 313.691 241.137 314.744 242.424"
          stroke="#300161"
          strokeWidth="3.52802"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M252.52 240.668C255.005 239.23 257.812 238.438 260.683 238.363C263.331 238.406 265.923 239.139 268.201 240.491"
          stroke="#300161"
          strokeWidth="3.52802"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M348.687 205.603C349.614 200.897 350.226 195.046 346.986 191.098C344.706 188.293 341.934 188.116 338.928 186.94C339.387 181.46 338.993 176.101 335.149 171.774C330.886 166.939 325.527 165.996 319.636 167.785C320.547 167.511 316.88 160.21 316.413 159.557C314.719 157.132 312.519 155.103 309.966 153.61C299.337 147.252 285.05 148.928 276.967 158.445C271.487 155.222 265.113 156.656 260.673 160.863C257.578 160.057 254.5 159.17 251.301 159.17C237.561 159.17 223.741 171.508 223.741 185.586C223.835 187.352 224.031 189.112 224.33 190.856C224.386 191.388 221.364 193.967 220.905 194.49C212.846 203.749 209.454 216.458 214.853 227.973C219.196 237.248 228.745 243.155 238.665 244.726C251.712 246.781 262.591 239.94 271.447 231.027C280.65 236.289 291.657 236.781 299.861 229.045C300.116 228.76 300.437 228.541 300.796 228.408C301.172 228.336 301.56 228.361 301.924 228.481C310.885 230.648 318.798 227.425 325.293 221.228C327.783 225.33 325.938 232.671 326.736 237.345C326.944 238.903 327.485 240.398 328.323 241.729C328.895 242.568 329.672 243.248 330.58 243.702C331.489 244.156 332.498 244.37 333.513 244.323C342.377 244.323 347.833 230.229 349.888 223.372C351.66 217.465 351.959 210.994 348.655 205.571"
          fill="#300161"
        />
        <path
          d="M348.687 205.603C349.614 200.897 350.226 195.046 346.986 191.098C344.706 188.293 341.934 188.116 338.928 186.94C339.387 181.46 338.993 176.101 335.149 171.774C330.886 166.939 325.527 165.996 319.636 167.785C320.547 167.511 316.88 160.21 316.413 159.557C314.719 157.132 312.519 155.103 309.966 153.61C299.337 147.252 285.05 148.928 276.967 158.445C271.487 155.222 265.113 156.656 260.673 160.863C257.578 160.057 254.5 159.17 251.301 159.17C237.561 159.17 223.741 171.508 223.741 185.586C223.835 187.352 224.031 189.112 224.33 190.856C224.386 191.388 221.364 193.967 220.905 194.49C212.846 203.749 209.454 216.458 214.853 227.973C219.196 237.248 228.745 243.155 238.665 244.726C251.712 246.781 262.591 239.94 271.447 231.027C280.65 236.289 291.657 236.781 299.861 229.045C300.116 228.76 300.437 228.541 300.796 228.408C301.172 228.336 301.56 228.361 301.924 228.481C310.885 230.648 318.798 227.425 325.293 221.228C327.783 225.33 325.938 232.671 326.736 237.345C326.944 238.903 327.485 240.398 328.323 241.729C328.895 242.568 329.672 243.248 330.58 243.702C331.489 244.156 332.498 244.37 333.513 244.323C342.377 244.323 347.833 230.229 349.888 223.372C351.66 217.465 351.959 210.994 348.655 205.571"
          stroke="#300161"
          strokeWidth="3.52802"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M264.248 251.059C264.554 253.324 264.697 255.607 264.675 257.892C264.693 260.18 264.551 262.466 264.248 264.734C264.248 265.162 264.078 265.571 263.776 265.874C263.474 266.176 263.064 266.346 262.636 266.346C262.209 266.346 261.799 266.176 261.497 265.874C261.194 265.571 261.024 265.162 261.024 264.734C260.719 262.467 260.574 260.18 260.589 257.892C260.566 255.607 260.711 253.323 261.024 251.059C261.024 250.632 261.194 250.222 261.497 249.919C261.799 249.617 262.209 249.447 262.636 249.447C263.064 249.447 263.474 249.617 263.776 249.919C264.078 250.222 264.248 250.632 264.248 251.059Z"
          fill="#300161"
        />
        <path
          d="M307.832 251.059C308.146 253.323 308.291 255.607 308.268 257.892C308.283 260.18 308.138 262.467 307.832 264.734C307.803 264.946 307.731 265.15 307.622 265.334C307.513 265.518 307.369 265.678 307.197 265.806C307.025 265.933 306.83 266.025 306.622 266.077C306.414 266.128 306.198 266.137 305.987 266.104C305.64 266.057 305.317 265.897 305.068 265.65C304.82 265.403 304.658 265.081 304.609 264.734C304.31 262.466 304.167 260.18 304.182 257.892C304.16 255.607 304.303 253.324 304.609 251.059C304.609 250.632 304.779 250.222 305.081 249.919C305.383 249.617 305.793 249.447 306.221 249.447C306.648 249.447 307.058 249.617 307.36 249.919C307.663 250.222 307.832 250.632 307.832 251.059Z"
          fill="#300161"
        />
        <path
          d="M260.271 340.976C264.134 345.097 268.435 348.783 273.1 351.968C273.905 352.54 279.595 357.319 280.723 357.955C277.5 361.453 274.389 363.919 271.859 367.948C265.009 363.338 245.161 344.715 245.161 337.157C245.161 334.973 249.247 332.072 252.132 330.855C253.026 330.484 254.654 333.579 255.025 334.151C256.324 336.148 257.748 338.062 259.288 339.88C259.626 340.251 259.948 340.622 260.271 340.976Z"
          fill="#41FFD1"
          stroke="#300161"
          strokeWidth="3.52802"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M291.297 367.942C288.404 364.316 285.309 362.035 283.238 357.861C287.203 354.275 291.627 351.293 295.632 347.764C301.449 342.712 306.377 336.719 310.21 330.035C313.707 330.414 317.962 334.177 317.962 336.369C317.962 338.787 315.544 341.502 314.231 343.42C311.056 348.063 307.457 352.4 303.481 356.378C302.45 357.418 290.999 367.571 291.297 367.942Z"
          fill="#41FFD1"
          stroke="#300161"
          strokeWidth="3.52802"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M281.74 358.701V399.992"
          stroke="#300161"
          strokeWidth="3.52802"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M403.228 103.814H158.838C153.867 103.814 149.838 107.844 149.838 112.814V390.993C149.838 395.964 153.867 399.993 158.838 399.993H403.228C408.198 399.993 412.228 395.964 412.228 390.993V112.814C412.228 107.844 408.198 103.814 403.228 103.814Z"
          stroke="white"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Image>
  )
}
