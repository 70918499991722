import axios from 'axios'
import { observer } from 'mobx-react'
import React, { useContext, useEffect, useState } from 'react'

import { StoreContext } from '../../../components/App'
import { Container, PollingLoadingBox } from './enroll.styles'
import { PageHeading } from '../../../components/PageHeading/PageHeading'
import { LoadingSpinner } from '../../../components/LoadingSpinner/LoadingSpinner'

export const AddressVerificationPolling = observer(() => {
  const store = useContext(StoreContext)

  const {
    enrollCompanyPage: { addressVerificationPolling: trans },
  } = store.TranslationsState.translations

  const [isPolling, setIsPolling] = useState(true)

  const pollVerificationData = async () => {
    try {
      const res = await axios.get<{
        redirectUrl?: string
      }>(`${process.env.WEB_API_URL}/merchant-enrolment/should-continue`, {
        withCredentials: true,
      })

      if (res.data.redirectUrl) {
        setIsPolling(false)
        window.location.href = res.data.redirectUrl
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (isPolling) {
      const intervalId = setInterval(pollVerificationData, 3000) // Poll every 3 seconds

      // Cleanup on unmount or when stopping polling
      return () => clearInterval(intervalId)
    }
  }, [isPolling])

  return (
    <Container>
      <PageHeading title={trans.title} subtitle={trans.subtitle} />

      <PollingLoadingBox>
        <LoadingSpinner
          bgColor="#F7F9FC"
          primary="#0071F3"
          bgIframe="#FFF"
          width="50px"
        />
        <p>
          {trans.description.split(',').map((item, key) => {
            return (
              <div key={key}>
                {item}
                {key === 0 && ','}
              </div>
            )
          })}
        </p>
      </PollingLoadingBox>
    </Container>
  )
})
