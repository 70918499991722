import axios from 'axios'
import { observer } from 'mobx-react'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import IconPlus from '../../../assets/icon_plus_blue.svg'
import { StoreContext } from '../../../components/App'
import { PageHeading } from '../../../components/PageHeading/PageHeading'
import { PersonCard } from '../../../components/PersonCard/PersonCard'
import { PersonCardAdd } from '../../../components/PersonCard/PersonCardAdd'
import { ToRight } from '../EnrollCompany.styles'
import {
  AddMore,
  Boxed,
  Container,
  Button,
  ProceedButton,
} from './enroll.styles'
import { ErrorInfo } from '../../../components/ErrorInfo/ErrorInfo'
import { AssignActorInput } from '../../../components/AssignActorInput/AssignActorInput'
import { toast } from 'react-toastify'

export interface ActorInfo {
  firstName: string
  infix: string
  lastName: string
  email: string
  phoneNumber: string
  dateOfBirth: number
}
export interface Actor {
  actorId: string
  info?: ActorInfo | undefined
  address?:
    | {
        line1: string
        line2: string
        city: string
        postcode: string
        country: string
      }
    | undefined
  providerInfo?: {
    name: string
    dateOfBirth?: number
    address?: string
  }
  isDirectorFromProvider?: boolean
  isUboFromProvider?: boolean
  isApplicant?: boolean
  isDirector?: boolean
  isUbo?: boolean
  isIdentityVerificationStarted?: boolean
  isAddressVerifciaitonStarted?: boolean
  lastEmailSent?: number
  successfulVerification?: number
  isVerified?: boolean
  isEmailSent?: boolean
  isNew?: boolean
}

export const blankActor: Actor = {
  actorId: '',
  info: {
    firstName: '',
    infix: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    dateOfBirth: 0,
  },
  address: {
    line1: '',
    line2: '',
    city: '',
    postcode: '',
    country: '',
  },
  providerInfo: {
    name: '',
    dateOfBirth: 0,
    address: '',
  },
}

let nextId = 0

export const infoOnlyRequiredFields = (info: ActorInfo) => {
  return {
    firstName: info.firstName || blankActor.info.firstName,
    lastName: info.lastName || blankActor.info.lastName,
    email: info.email || blankActor.info.email,
    phoneNumber: info.phoneNumber || blankActor.info.phoneNumber,
    dateOfBirth: info.dateOfBirth || blankActor.info.dateOfBirth,
  }
}

export const Directors = observer(() => {
  const navigate = useNavigate()
  const store = useContext(StoreContext)
  const { theme } = store.AppState
  const {
    enrollCompanyPage: { directors: trans },
  } = store.TranslationsState.translations

  const [isDisabled, setIsDisabled] = useState(false)
  const [isProceedDisabled, setIsProceedDisabled] = useState(false)
  const [people, setPeople] = useState<Actor[]>([])
  const [initialCheckboxesState, setInitialCheckboxesState] = useState<
    Record<string, boolean>
  >({})
  const [applicantAsDirector, setApplicantAsDirector] = useState<number[]>([])

  const addApplicantToDirectorsHandler = (ids) => {
    setApplicantAsDirector(ids)
  }

  const handleAddPerson = () => {
    setPeople([
      ...people,
      {
        ...blankActor,
        actorId: `new${nextId++}`,
        isDirector: true,
        isUbo: false,
        isNew: true,
      },
    ])
  }

  const handleDeletePerson = (id: string) => {
    console.log('delete')

    setPeople((prevItems) =>
      prevItems.filter((person) => person.actorId !== id)
    )
  }

  const handleChangePerson = (actor: Actor) => {
    console.log(actor)

    setPeople((prevItems) =>
      prevItems.map((person) =>
        person.actorId === actor.actorId ? actor : person
      )
    )
  }

  const handleProceed = async () => {
    try {
      setIsDisabled(true)
      await axios.post(
        `${process.env.WEB_API_URL}/merchant-enrolment/actors`,
        {
          edit: people
            .filter(
              (person) =>
                (person.isApplicant || person.isDirector) && !person.isNew
            )
            .map((person) => {
              if (person.isApplicant) {
                return {
                  ...(person.actorId in applicantAsDirector &&
                  applicantAsDirector[person.actorId] !== person.isDirector
                    ? {
                        actorId: person.actorId,
                        isApplicant: person.isApplicant,
                        isUbo: person.isUbo,
                        isDirector: applicantAsDirector[person.actorId],
                      }
                    : null),
                }
              } else {
                return {
                  ...person,
                  info: {
                    ...(person.info ? person.info : blankActor.info),
                    infix: person.info?.infix || '',
                    dateOfBirth:
                      person.info?.dateOfBirth ||
                      person.providerInfo?.dateOfBirth ||
                      0,
                  },
                  isDirector:
                    person.actorId in applicantAsDirector
                      ? applicantAsDirector[person.actorId]
                      : person.isDirector,
                }
              }
            })
            .filter((person) => person.actorId),
          add: people
            .filter(
              (person) =>
                person.isNew &&
                Object.values(infoOnlyRequiredFields(person.info)).every(
                  (x) => x !== null && x !== '' && x !== 0
                )
            )
            .map((person) => {
              return {
                ...Object.fromEntries(
                  Object.entries(person).filter(
                    ([key, value]) => key !== 'actorId' && key !== 'isNew'
                  )
                ),
                info: {
                  ...person.info,
                  infix: person.info?.infix || '',
                },
              }
            }),
        },
        {
          withCredentials: true,
        }
      )

      navigate('/enroll-company/ubos')

      setIsDisabled(false)
    } catch (e) {
      if (axios.isAxiosError(e) && e.response) {
        toast(e.response.data.message, { type: 'error' })
      }
      setIsDisabled(false)
      console.log(e)
    }
  }

  const getActors = async () => {
    try {
      setIsDisabled(true)
      const res = await axios.get(
        `${process.env.WEB_API_URL}/merchant-enrolment/actors`,
        { withCredentials: true }
      )

      if (res.data) {
        setPeople(res.data.actors)
        setInitialCheckboxesState(
          res.data.actors
            ?.filter((person) => person.isApplicant)
            .reduce((acc, actor) => {
              acc[actor.actorId] = actor.isDirector
              return acc
            }, {} as Record<string, boolean>)
        )
      }

      setIsDisabled(false)
    } catch (e) {
      setIsDisabled(false)
      console.log(e)
    }
  }

  useEffect(() => {
    getActors()
  }, [])

  useEffect(() => {
    setIsProceedDisabled(true)
    const isRequiredDataSet = people
      .filter((person) => person.isDirector)
      .every((person) =>
        Object.values(infoOnlyRequiredFields(person.info)).every(
          (x) => x !== null && x !== '' && x !== 0
        )
      )

    if (isRequiredDataSet) setIsProceedDisabled(false)
  }, [people])

  // const initialCheckboxesState = useMemo(
  //   () =>
  //     people
  //       ?.filter((person) => person.isApplicant)
  //       .reduce((acc, actor) => {
  //         acc[actor.actorId] = actor.isDirector
  //         return acc
  //       }, {} as Record<string, boolean>),
  //   [people]
  // )
  console.log(theme.button.borderRadius)
  return (
    <Container>
      <PageHeading
        title={trans.title}
        subtitle={trans.subtitle}
        {...theme.pageHeading}
      />
      <Boxed>
        {people
          .filter(
            (person) =>
              !person.isApplicant && person.isDirector && !person.isNew
          )
          .map((person) => (
            <PersonCard
              key={person.actorId}
              person={person}
              onChange={handleChangePerson}
              onDelete={handleDeletePerson}
              initialEdit={true}
              isLoading={isDisabled}
            />
          ))}

        {people
          .filter((person) => person.isNew)
          .map((person) => (
            <PersonCardAdd
              key={person.actorId}
              person={person}
              onChange={handleChangePerson}
              onDelete={handleDeletePerson}
              isLoading={isDisabled}
            />
          ))}

        <AssignActorInput
          title={trans.assignApplicantAsDirector}
          actors={people.filter((person) => person.isApplicant)}
          initialCheckboxesState={initialCheckboxesState}
          onChange={addApplicantToDirectorsHandler}
        />

        <AddMore>
          <h3>{trans.addANewDirector}</h3>
          <span>{trans.clickHereToAddANewDirector}</span>
          <Button onClick={handleAddPerson} {...theme.button}>
            <img src={IconPlus} alt="Add" />
            {trans.addNewDirector}
          </Button>
        </AddMore>

        <ToRight>
          <ProceedButton
            onClick={handleProceed}
            disabled={isProceedDisabled}
            {...theme.proceedButton}
          >
            <span>{trans.proceed}</span>
          </ProceedButton>
        </ToRight>
      </Boxed>
    </Container>
  )
})
