import React, { useState, useEffect } from 'react'
import { Container, Overlay, Wrap } from './SlidingPanel.styles'

interface SlidingPanelProps {
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  [x: string]: any
  visibility: boolean
  handleVisibility: (boolean) => void
  children?: React.ReactNode
  side?: string
}

export const SlidingPanel = ({
  visibility,
  handleVisibility,
  children,
  side,
}: SlidingPanelProps) => {
  const [visible, setVisible] = useState(false)

  function setVisibility(show: boolean) {
    setVisible(show)
    handleVisibility(show)
  }

  useEffect(() => {
    setVisible(visibility)
  }, [visibility])

  return (
    <Wrap side={side}>
      <Container visible={visible} side={side}>
        {children}
      </Container>
      <Overlay visible={visible} onClick={() => setVisibility(false)} />
    </Wrap>
  )
}
