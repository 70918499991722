import React, { FC } from 'react'

export const CaretDown: FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.03999 14.9581C5.78615 14.7042 5.78615 14.2927 6.03999 14.0388L11.04 9.03882C11.2938 8.78498 11.7054 8.78498 11.9592 9.03882L16.9592 14.0388C17.2131 14.2927 17.2131 14.7042 16.9592 14.9581C16.7054 15.2119 16.2938 15.2119 16.04 14.9581L11.4996 10.4177L6.95923 14.9581C6.70539 15.2119 6.29383 15.2119 6.03999 14.9581Z"
        fill="#303438"
      />
    </svg>
  )
}
