import styled from 'styled-components'

export const ContentWrap = styled.div`
  @media (max-width: 980px) {
    width: 100%;
    min-width: auto;
    margin: 0;
    padding: 0;

    > div {
      height: calc(100vh - 120px);
      position: relative;
      border-bottom: none;
      border: none;
      box-shadow: none;
    }
  }

  button {
    transition: all 0.2s ease-in-out;
  }

  a {
    font-weight: 700;
  }
`

export const InnerAppLayout = styled.div`
  display: flex;
  height: calc(100% - 62px);
  @media (max-width: 980px) {
    height: 100%;
  }
`

export const InnerWizardLayout = styled.div`
  display: flex;
  height: 100%;
  @media (max-width: 980px) {
    height: 100%;
  }
`

interface InnerAppContentProps {
  padding?: string
  paddingMobile?: string
}

export const InnerAppContent = styled.div<InnerAppContentProps>`
  width: 100%;
  box-sizing: border-box;
  padding: ${(props) => (props.padding ? props.padding : '10px 40px 40px')};
  background-color: #ffffff;
  overflow: hidden;
  overflow-x: hidden;
  overflow-y: scroll;
  @media (max-width: 980px) {
    padding: ${(props) =>
      props.paddingMobile ? props.paddingMobile : '10px 40px 40px'};
  }
`

export const DevTools = styled.div`
  display: none;
  position: fixed;
  bottom: 20px;
  right: 20px;
  border: 1px solid gray;
  box-sizing: border-box;
  padding: 20px;
`

interface MarginerProps {
  margin?: string
}

export const Marginer = styled.div<MarginerProps>`
  margin: ${(props) => (props.margin ? props.margin : '0')};
`
