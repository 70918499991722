import React from 'react'
import { Container } from './NavMenu.styles'

interface NavMenuItem {
  name: string // needs to be unique
  label: string
  urls: string[]
}

interface NavMenuProps {
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  [x: string]: any
  items?: NavMenuItem[]
  margin?: string
  color?: string
  colorActive?: string
  border?: string
  fontFamily?: string
}

export const NavMenu = ({
  items,
  margin,
  color,
  colorActive,
  border,
  fontFamily,
}: NavMenuProps) => {
  return (
    <Container
      margin={margin}
      color={color}
      colorActive={colorActive}
      border={border}
      fontFamily={fontFamily}
    >
      {items.map((item) => {
        return (
          <li key={item.name}>
            <a
              href={item.urls[0]}
              className={
                item.urls.indexOf(window.location.pathname) !== -1
                  ? 'active'
                  : ''
              }
            >
              {item.label}
            </a>
          </li>
        )
      })}
    </Container>
  )
}
