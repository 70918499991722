import styled from 'styled-components'

export const Table = styled.table`
  width: 100%;
  padding: 0;
  margin: 0;
  font-family: 'Inter';
`

export const THead = styled.thead`
  color: #5a338b;
  text-align: left;
  text-transform: capitalize;

  tr {
    border-bottom: 1px solid #eae3f8;
  }

  th {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    border-bottom: 1px solid #eae3f8;
    padding: 9px 16px;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }
  }
`

export const HeaderCell = styled.div`
  display: flex;

  .sc-icon {
    margin-left: 8px;
  }
`

export const TBody = styled.tbody`
  td {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    border-bottom: 1px solid #eae3f8;
    padding: 9px 16px;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }
  }
`
