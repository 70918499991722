import React from 'react'
import { Container } from './Icons.styles'

interface HamburgerMenuProps {
  color: string
  thickness?: string
  size?: string
  colorHover?: string
}

export const HamburgerMenu = ({
  color,
  thickness,
  size,
  colorHover,
}: HamburgerMenuProps) => {
  return (
    <Container size={size} color={color} colorHover={colorHover}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M15 30C23.2843 30 30 23.2843 30 15C30 6.71573 23.2843 0 15 0C6.71573 0 0 6.71573 0 15C0 23.2843 6.71573 30 15 30Z"
          fill="none"
        />
        <rect
          x="7.64703"
          y="10"
          width="14.1176"
          height="1.76471"
          fill={`${color ? color : '#280b60'}`}
          r={`${thickness ? thickness : '2'}`}
        />
        <rect
          x="7.64703"
          y="14.1177"
          width="14.1176"
          height="1.76471"
          fill={`${color ? color : '#280b60'}`}
          r={`${thickness ? thickness : '2'}`}
        />
        <rect
          x="7.64703"
          y="18.2354"
          width="14.1176"
          height="1.76471"
          fill={`${color ? color : '#280b60'}`}
          r={`${thickness ? thickness : '2'}`}
        />
      </svg>
    </Container>
  )
}
