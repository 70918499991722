import React from 'react'
import { Icon, IconProps } from './Icon.styles'

export const SortReverseIcon = ({ size, color, margin }: IconProps) => {
  return (
    <Icon size={size} margin={margin}>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="sort">
          <path
            id="Vector"
            fill={color ? color : '#272142'}
            d="M15,15.8c0,0.5-0.4,0.8-0.8,0.8c-0.5,0-0.8-0.4-0.8-0.8V6.2l-1.9,1.9c-0.3,0.3-0.9,0.3-1.2,0
		c-0.3-0.3-0.3-0.9,0-1.2l3.3-3.3c0.1-0.1,0.3-0.2,0.5-0.2c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0.2,0,0.3,0.1,0.5,0.2l3.3,3.3
		c0.3,0.3,0.3,0.9,0,1.2c-0.3,0.3-0.9,0.3-1.2,0L15,6.2V15.8z M5,4.2c0-0.5,0.4-0.8,0.8-0.8c0.5,0,0.8,0.4,0.8,0.8v9.7l1.9-1.9
		c0.3-0.3,0.9-0.3,1.2,0c0.3,0.3,0.3,0.9,0,1.2l-3.3,3.3c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.3,0.1-0.4,0.1s-0.3,0-0.4-0.1
		c-0.1,0-0.1-0.1-0.2-0.1l-3.3-3.3c-0.3-0.3-0.3-0.9,0-1.2c0.3-0.3,0.9-0.3,1.2,0L5,13.8V4.2z"
          />
        </g>
      </svg>
    </Icon>
  )
}
