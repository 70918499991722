import { action, makeAutoObservable } from 'mobx'
import * as translations from '../translations/index'
import { Language, TranslationsInterface } from '../types/translationsTypes'
import { languageList } from '../components/CountrySelect/languageList'

export class TranslationsStore {
  rootStore
  translations: TranslationsInterface = translations.en
  language = 'en'

  constructor(rootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
    this.restoreLanguageFromLocalStorage()
  }

  @action.bound setTranslations(language: Language): void {
    this.language = language
    this.translations = translations[language] ?? translations.en
    localStorage.setItem('language', language)
  }

  @action.bound restoreLanguageFromLocalStorage(): void {
    const languageLS = localStorage.getItem('language')
    const langs = languageList.map((lang) => lang.value)

    if (languageLS && langs.includes(languageLS.toLowerCase())) {
      this.setTranslations(languageLS as Language)
    }
  }
}
