import React, { FC, useRef } from 'react'
import { Container, Wrapper } from './AnimatedBorder.styles'

interface AnimatedBorderProps {
  isLoading?: boolean
  isFilled?: boolean
  children?: React.ReactNode
  height?: number
}

export const AnimatedBorder: FC<AnimatedBorderProps> = ({
  children,
  isLoading,
  isFilled,
  height = 0,
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null)

  return (
    <Container ref={wrapperRef}>
      <Wrapper
        isVisible={isLoading}
        isFilled={isFilled}
        width={wrapperRef.current?.offsetWidth || 0}
        height={height || 0}
      >
        {children}
      </Wrapper>
    </Container>
  )
}
