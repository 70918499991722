import styled from 'styled-components'

interface ContainerProps {
  width?: string
  padding?: string
}

export const Container = styled.div<ContainerProps>`
  width: ${(props) => (props.width ? props.width : '')};
  padding: ${(props) => (props.padding ? props.padding : '')};

  svg {
    width: 100%;
    height: auto;
  }
`
