import React from 'react'
import { Container } from './SignicatLogo.styles'

interface SignicatLogoProps {
  color?: string
  bgColor?: string
  width?: string
  padding?: string
}

export const SignicatLogo = ({
  color,
  bgColor,
  width,
  padding,
}: SignicatLogoProps) => {
  return (
    <Container
      width={width ? width : '105px'}
      padding={padding ? padding : '0'}
    >
      <svg
        width="105"
        height="57"
        viewBox="0 0 105 57"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="Group">
          <path
            id="Vector"
            d="M52.4946 2.41953V13.5587C47.506 5.5851 38.6539 0.279297 28.5472 0.279297C12.964 0.279297 0.328125 12.9216 0.328125 28.5006C0.328125 44.0795 12.964 56.7219 28.5572 56.7219C38.6539 56.7219 47.5159 51.416 52.5045 43.4424V54.5816H104.681V2.41953H52.4946Z"
            fill={color}
          />
          <g id="Group_2">
            <g id="Group_3">
              <path
                id="Vector_2"
                d="M18.8087 34.4129C22.2341 34.4129 24.5143 32.3921 24.5143 29.6645C24.5143 27.0664 22.7718 25.6727 19.5058 25.0257C17.5641 24.6574 15.6124 24.0103 15.6124 22.4275C15.6124 21.3923 16.5683 20.5063 18.291 20.5063C20.0136 20.5063 21.5371 21.3126 21.5371 22.5569C21.5371 22.8655 21.4574 23.0746 21.3777 23.3334L23.7078 24.1397C23.9666 23.6719 24.1558 23.1044 24.1558 22.4474C24.1558 19.8792 21.7959 18.1172 18.5299 18.1172C15.2839 18.1172 13.1629 20.138 13.1629 22.4176C13.1629 25.3243 15.3137 26.6981 18.6594 27.2456C21.1487 27.6935 21.9254 28.5397 21.9254 29.7342C21.9254 31.0283 20.7604 31.9939 18.8386 31.9939C17.0563 31.9939 15.4133 31.2174 15.4133 29.6347C15.4133 29.3758 15.4631 29.0872 15.5427 28.7786L13.2127 27.8229C12.9738 28.2709 12.7646 28.908 12.7646 29.565C12.7846 32.4916 15.0747 34.4129 18.8087 34.4129Z"
                fill={bgColor ? bgColor : 'white'}
              />
            </g>
            <g id="Group_4">
              <path
                id="Vector_3"
                d="M26.3662 20.3572C26.3662 21.2133 27.0134 21.8604 27.8698 21.8604C28.7261 21.8604 29.4032 21.2133 29.4032 20.3572C29.4032 19.5011 28.7361 18.8242 27.8698 18.8242C27.0234 18.8242 26.3662 19.5011 26.3662 20.3572Z"
                fill={bgColor ? bgColor : 'white'}
              />
            </g>
            <path
              id="Vector_4"
              d="M29.065 23.4629H26.7051V34.174H29.065V23.4629Z"
              fill={bgColor ? bgColor : 'white'}
            />
            <g id="Group_5">
              <path
                id="Vector_5"
                d="M36.4136 32.1338C34.6711 32.1338 33.3269 30.9193 33.3269 28.8687C33.3269 26.609 34.6811 25.2651 36.4136 25.2651C38.1761 25.2651 39.5004 26.7185 39.5004 28.7094C39.4904 30.7103 38.1462 32.1338 36.4136 32.1338ZM39.3909 23.4434V24.8968C38.7237 23.8814 37.6981 23.2344 35.9954 23.2344C33.1675 23.2344 30.9072 25.4642 30.9072 28.8587C30.9072 32.1238 33.058 34.1745 35.7564 34.1745C37.5687 34.1745 38.8133 33.398 39.361 32.2532V33.398C39.361 35.4188 38.5843 36.8423 36.5331 36.8423C35.0495 36.8423 33.9343 36.0957 33.5957 34.6423L31.4449 35.5482C32.2216 37.9074 34.0438 38.8929 36.5331 38.8929C39.9286 38.8929 41.7209 36.5636 41.7209 33.2885V23.4335H39.3909V23.4434Z"
                fill={bgColor ? bgColor : 'white'}
              />
            </g>
            <g id="Group_6">
              <path
                id="Vector_6"
                d="M46.5501 28.1918C46.5501 26.3004 47.6952 25.2552 49.0892 25.2552C50.4136 25.2552 51.2898 26.2108 51.2898 27.9827V34.1844H53.6497V27.1764C53.6497 24.6579 52.0665 23.2344 49.8858 23.2344C48.5615 23.2344 47.3168 23.7321 46.5103 25.0162V23.4534H44.1504V34.1645H46.5103V28.1719L46.5501 28.1918Z"
                fill={bgColor ? bgColor : 'white'}
              />
            </g>
            <g id="Group_7">
              <path
                id="Vector_7"
                d="M62.5609 28.7395C62.5609 26.3802 64.2536 25.2653 65.7273 25.2653C67.3105 25.2653 68.4556 26.2508 68.6647 27.8336L70.8155 27.1666C70.3674 24.678 68.3262 23.2246 65.7273 23.2246C62.7899 23.2246 60.3105 25.4544 60.3105 28.7494C60.3105 32.1439 62.5709 34.4036 66.0659 34.4036C68.6647 34.4036 70.8155 32.9204 71.3831 30.1032L69.2323 29.3268C68.9933 30.9096 68.0872 32.3828 65.9663 32.3828C64.2736 32.3729 62.5609 30.9892 62.5609 28.7395Z"
                fill={bgColor ? bgColor : 'white'}
              />
            </g>
            <g id="Group_8">
              <path
                id="Vector_8"
                d="M77.9955 32.2817C76.3027 32.2817 74.8091 30.9578 74.8091 28.8573C74.8091 26.7569 76.2927 25.3533 77.9955 25.3533C79.5787 25.3533 81.2117 26.6773 81.2117 28.8573C81.1818 31.0374 79.5588 32.2817 77.9955 32.2817ZM81.1121 23.4321V25.1741C80.4947 23.9597 79.2202 23.2031 77.5075 23.2031C74.5303 23.2031 72.4492 25.5624 72.4492 28.8573C72.4492 32.0229 74.5801 34.3821 77.5075 34.3821C79.1405 34.3821 80.3852 33.7152 81.2415 32.3613V34.1731H83.5019V23.4321H81.1121Z"
                fill={bgColor ? bgColor : 'white'}
              />
            </g>
            <g id="Group_9">
              <path
                id="Vector_9"
                d="M90.9699 34.3922C92.9912 34.3922 94.3654 33.1479 94.5745 30.9977L92.3141 30.5497C92.2345 31.794 91.7366 32.3615 90.8305 32.3615C90.1634 32.3615 89.4763 31.9135 89.4763 30.8782V25.4828H93.5489V23.4322H89.4763V20.0078H87.1264V23.4322H84.9756V25.453H87.1264V30.7588C87.1065 32.909 88.3511 34.3922 90.9699 34.3922Z"
                fill={bgColor ? bgColor : 'white'}
              />
            </g>
            <g id="Group_10">
              <path
                id="Vector_10"
                d="M55.75 20.3572C55.75 21.2133 56.3972 21.8604 57.2536 21.8604C58.1099 21.8604 58.787 21.2133 58.787 20.3572C58.787 19.5011 58.1198 18.8242 57.2536 18.8242C56.3972 18.8242 55.75 19.5011 55.75 20.3572Z"
                fill={bgColor ? bgColor : 'white'}
              />
            </g>
            <path
              id="Vector_11"
              d="M58.4488 23.4629H56.0889V34.174H58.4488V23.4629Z"
              fill={bgColor ? bgColor : 'white'}
            />
          </g>
        </g>
      </svg>
    </Container>
  )
}
