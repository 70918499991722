import { observer } from 'mobx-react'
import React, { useContext } from 'react'
import { StoreContext } from '../../../components/App'
import { Circle, InfoPage } from './enroll.styles'
import IconSuccess from '../../../assets/icon_success.svg'

export const Success = observer(() => {
  const store = useContext(StoreContext)

  const {
    enrollCompanyPage: { success: trans },
  } = store.TranslationsState.translations

  return (
    <InfoPage>
      <main>
        <Circle>
          <img src={IconSuccess} alt="Error" />
        </Circle>
        <h1>{trans.title}</h1>
        <br />
        <h2>{trans.description1}</h2>
        <br />
        <h2>{trans.description2}</h2>
        <br />
        <h2>{trans.description3}</h2>
      </main>
    </InfoPage>
  )
})
