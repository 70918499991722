import { observer } from 'mobx-react'
import React, { useContext } from 'react'
import { useSearchParams } from 'react-router-dom'
import { StoreContext } from '../../../components/App'
import { Circle, InfoPage } from './enroll.styles'
import IconError from '../../../assets/icon_error.svg'

export const Error = observer(() => {
  const store = useContext(StoreContext)
  const [searchParams] = useSearchParams()
  const {
    enrollCompanyPage: { error: trans },
  } = store.TranslationsState.translations

  return (
    <InfoPage>
      <main>
        <h1>{trans.title}</h1>
        <Circle>
          <img src={IconError} alt="Error" />
        </Circle>
        <h2>{searchParams.get('error') || trans.description}</h2>
      </main>
    </InfoPage>
  )
})
