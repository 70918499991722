import React from 'react'
import { Container } from './CheckoutLogo.styles'

interface CheckoutLogoProps {
  color?: string
  bgColor?: string
  width?: string
  padding?: string
}

export const CheckoutLogo = ({
  color,
  bgColor,
  width,
  padding,
}: CheckoutLogoProps) => {
  return (
    <Container
      width={width ? width : '149px'}
      padding={padding ? padding : '0'}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="149"
        height="20"
        viewBox="0 0 149 20"
        fill="none"
      >
        <mask
          id="mask0_522_12549"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="149"
          height="20"
        >
          <path d="M149 0.197266H0V19.8027H149V0.197266Z" fill={color} />
        </mask>
        <g mask="url(#mask0_522_12549)">
          <path
            d="M6.86585 18.604L10.9993 11.2035L15.1328 18.604H6.86585ZM5.84456 1.98792L10.3192 9.99998L5.84456 18.012L1.24713 9.99998L5.84456 1.98792ZM10.9993 8.79726L6.86427 1.39509H15.1328L10.9993 8.79726ZM11.6707 10.0008L16.6449 1.093C16.8049 0.80633 16.7083 0.44008 16.4281 0.276038C16.3402 0.224064 16.2397 0.197266 16.1382 0.197266H5.8588C5.65217 0.197266 5.46058 0.310146 5.35607 0.492865L0.08016 9.69705C-0.02672 9.88383 -0.02672 10.116 0.08016 10.3029L5.35607 19.507C5.46058 19.6906 5.65217 19.8027 5.8588 19.8027H16.1382C16.4605 19.8027 16.7218 19.5347 16.7225 19.2041C16.7225 19.0994 16.6965 18.9971 16.6458 18.9061L11.6707 9.9991V10.0008Z"
            fill={color}
          />
          <path
            d="M25.3153 15.234C24.6763 14.9579 24.1008 14.5479 23.6242 14.0306C23.1515 13.5067 22.7857 12.892 22.5474 12.2212C22.291 11.5033 22.1627 10.7448 22.1682 9.98065C22.1619 9.2181 22.287 8.46042 22.5371 7.74254C22.7683 7.07338 23.1278 6.45863 23.5933 5.93322C24.0548 5.41511 24.6161 5.00094 25.2417 4.7159C25.9273 4.41217 26.6683 4.26194 27.4149 4.27412C28.0419 4.26519 28.6657 4.36427 29.2611 4.5681C29.7796 4.74838 30.2618 5.02286 30.6862 5.37856C31.0899 5.72288 31.4248 6.14436 31.6711 6.62024C31.93 7.12374 32.1065 7.66702 32.1936 8.22898H29.4686C29.3838 7.77178 29.1582 7.35437 28.8233 7.04009C28.4718 6.7185 28.0126 6.54878 27.5416 6.56745C26.6936 6.56745 26.0579 6.87198 25.6343 7.48186C25.2108 8.09174 24.9985 8.9225 24.9978 9.97416C24.9978 10.9982 25.1996 11.8225 25.6034 12.4462C26.0072 13.0698 26.6398 13.3825 27.4995 13.3825C28.0079 13.4044 28.5082 13.2444 28.9152 12.931C29.2864 12.6289 29.5192 12.1692 29.6118 11.5521H32.2989C32.2253 12.6711 31.7289 13.7171 30.9142 14.4658C30.4875 14.8515 29.9934 15.1512 29.4583 15.3485C28.8502 15.5727 28.2074 15.6831 27.5605 15.6742C26.7902 15.6872 26.0262 15.537 25.3144 15.2324L25.3153 15.234Z"
            fill={color}
          />
          <path
            d="M36.6341 5.98039C36.9595 5.46066 37.3934 5.02132 37.9049 4.69405C38.3696 4.4082 38.9641 4.26526 39.6893 4.26526C40.2008 4.25796 40.7074 4.36191 41.1762 4.57142C41.6092 4.76633 41.9972 5.05218 42.3146 5.41193C42.6368 5.78468 42.8878 6.21672 43.0533 6.68529C43.2362 7.20827 43.3264 7.76048 43.3193 8.31595V15.3641H40.5333V8.7358C40.5562 8.21607 40.3884 7.70608 40.0622 7.30653C39.7471 6.94191 39.2959 6.75919 38.7084 6.75919C38.1241 6.74294 37.5628 6.99388 37.1757 7.44378C36.7719 7.90017 36.5692 8.49949 36.5692 9.24173V15.3632H33.7832V0.321777H36.5692V5.98039H36.6341Z"
            fill={color}
          />
          <path
            d="M48.3482 7.10441C47.9658 7.51857 47.7125 8.08298 47.5889 8.79761H52.2671C52.2529 8.17555 52.0153 7.58029 51.6013 7.12553C51.1912 6.69593 50.6236 6.46368 50.0385 6.48398C49.295 6.48398 48.733 6.69106 48.3513 7.10441H48.3482ZM47.9302 15.2342C46.6207 14.6877 45.6105 13.5816 45.1623 12.2059C44.9193 11.4824 44.7974 10.7206 44.8029 9.95564C44.7974 9.19147 44.9257 8.43218 45.1821 7.7151C45.4189 7.04919 45.7815 6.43769 46.2494 5.91633C47.2145 4.85168 48.5738 4.25561 49.9909 4.27591C50.7589 4.26048 51.5205 4.42209 52.2196 4.74855C52.8538 5.05795 53.4087 5.51516 53.8395 6.08443C54.2947 6.69675 54.6327 7.3927 54.8338 8.13495C55.0673 8.99414 55.1806 9.88337 55.1711 10.7758H47.551C47.6594 11.6164 47.9381 12.275 48.387 12.7524C48.8358 13.23 49.4542 13.4679 50.2427 13.4672C50.789 13.4672 51.226 13.3469 51.5553 13.1097C51.8839 12.8694 52.1341 12.5316 52.2727 12.1426H55.0215C54.9241 12.6226 54.7468 13.0813 54.4974 13.4988C54.2393 13.9324 53.9059 14.3133 53.5141 14.6235C53.0842 14.9605 52.6028 15.2196 52.089 15.3926C51.5 15.589 50.8832 15.6849 50.2641 15.6784C49.4653 15.6938 48.6712 15.5428 47.931 15.2359L47.9302 15.2342Z"
            fill={color}
          />
          <path
            d="M59.3481 15.236C58.7084 14.9599 58.132 14.5498 57.6554 14.0325C57.1835 13.5079 56.8178 12.8932 56.5787 12.2232C56.3222 11.5053 56.194 10.7468 56.1994 9.98263C56.1931 9.22008 56.3183 8.4624 56.5684 7.74452C56.7996 7.07535 57.159 6.46061 57.6246 5.93519C58.0877 5.41951 58.6498 5.00779 59.2752 4.72518C59.9609 4.42146 60.7019 4.27122 61.4485 4.2834C62.0755 4.27447 62.6993 4.37355 63.2947 4.57738C63.8133 4.75766 64.2954 5.03215 64.7198 5.38784C65.1243 5.73217 65.4585 6.15364 65.7047 6.62952C65.9635 7.13301 66.1401 7.6763 66.2272 8.23827H63.4998C63.415 7.78106 63.1894 7.36365 62.8545 7.04937C62.5031 6.72778 62.0438 6.55806 61.5728 6.57674C60.7248 6.57674 60.0891 6.88127 59.6656 7.49115C59.242 8.10102 59.0298 8.93179 59.029 9.98344C59.029 11.0075 59.2309 11.8317 59.6346 12.4555C60.0385 13.0791 60.671 13.3917 61.5308 13.3917C62.0391 13.4137 62.5395 13.2537 62.9464 12.9402C63.3177 12.6382 63.5505 12.1785 63.6431 11.5613H66.3301C66.2937 12.12 66.1519 12.6665 65.9121 13.1701C65.6762 13.6646 65.3484 14.108 64.9478 14.4751C64.5211 14.8609 64.0271 15.1605 63.4918 15.3579C62.8838 15.5819 62.241 15.6924 61.5941 15.6835C60.823 15.694 60.0583 15.5414 59.3473 15.2343L59.3481 15.236Z"
            fill={color}
          />
          <path
            d="M70.5823 11.7219V15.3616H67.8145V0.321777H70.5823V8.65134L74.3144 4.5698H77.6577L73.7807 8.67246L78.1288 15.3616H74.745L71.792 10.4387L70.5823 11.7219Z"
            fill={color}
          />
          <path
            d="M85.4079 12.5182C85.8655 11.8734 86.0944 11.0248 86.0944 9.97311C86.0944 8.92146 85.8655 8.06958 85.4079 7.41829C84.9503 6.76699 84.29 6.44053 83.4278 6.43972C82.5657 6.43891 81.907 6.76537 81.4494 7.41829C80.991 8.06958 80.7614 8.92146 80.763 9.97311C80.7638 11.0248 80.9926 11.8734 81.4494 12.5182C81.907 13.1638 82.5665 13.4871 83.4278 13.4871C84.2892 13.4871 84.9487 13.1646 85.4079 12.5198V12.5182ZM81.1937 15.233C79.8778 14.671 78.8439 13.5804 78.3332 12.2152C77.8004 10.7722 77.8004 9.17889 78.3332 7.73581C78.8446 6.3707 79.8778 5.28006 81.1937 4.71647C82.6282 4.12771 84.229 4.12771 85.6645 4.71647C86.981 5.28169 88.015 6.37557 88.5248 7.74312C89.06 9.1862 89.06 10.7803 88.5248 12.2226C88.0142 13.5877 86.9802 14.6784 85.6645 15.2403C84.2298 15.8291 82.6291 15.8291 81.1937 15.2403V15.233Z"
            fill={color}
          />
          <path
            d="M97.0934 14.0993H97.0328C96.706 14.5614 96.2907 14.9504 95.8129 15.2428C95.3561 15.5164 94.7576 15.6528 94.0189 15.6528C92.8432 15.6528 91.9407 15.302 91.3121 14.6012C90.6835 13.9004 90.3691 12.9535 90.3691 11.7621V4.57031H93.1369V11.2862C93.1369 11.9181 93.2763 12.4021 93.555 12.7383C93.8336 13.0745 94.2746 13.2426 94.8787 13.2426C95.535 13.2426 96.0512 13.0152 96.4263 12.5596C96.8021 12.1041 96.9877 11.5186 96.9838 10.8023V4.57031H99.7697V15.3621H97.0953V14.0985L97.0934 14.0993Z"
            fill={color}
          />
          <path
            d="M102.493 4.56857V1.2041H105.221V4.56857H107.025V6.45587H105.221V12.1721C105.221 12.579 105.316 12.8592 105.507 13.0135C105.726 13.1767 105.994 13.2587 106.265 13.2449H106.798C106.894 13.2449 106.99 13.2376 107.085 13.2238V15.3271C106.935 15.3417 106.784 15.349 106.634 15.3482C106.483 15.3628 106.299 15.3685 106.076 15.3685H105.343C105.001 15.3685 104.661 15.3296 104.328 15.2524C104 15.1785 103.689 15.0396 103.414 14.8423C103.132 14.636 102.904 14.3616 102.749 14.0432C102.566 13.6502 102.477 13.2173 102.492 12.7812V6.45668H101.035V4.56939H102.492L102.493 4.56857Z"
            fill={color}
          />
          <path
            d="M110.352 12.5942C111.178 12.5942 111.846 13.2804 111.846 14.1258C111.846 14.9712 111.178 15.6574 110.352 15.6574C109.527 15.6574 108.859 14.9712 108.859 14.1258C108.859 13.2804 109.527 12.5942 110.352 12.5942Z"
            fill={color}
          />
          <path
            d="M115.867 15.2022C115.283 14.9236 114.768 14.5143 114.359 14.0035C113.936 13.4684 113.619 12.8536 113.426 12.1942C113.208 11.4617 113.101 10.6991 113.108 9.93336C113.102 9.16675 113.212 8.4042 113.436 7.6725C113.636 7.0139 113.957 6.39997 114.38 5.86318C114.79 5.34994 115.309 4.93984 115.896 4.66454C116.541 4.36812 117.24 4.22196 117.947 4.23576C119.123 4.23576 120.083 4.54435 120.828 5.15991C121.573 5.77628 122.034 6.64602 122.213 7.76915H120.947C120.796 6.95625 120.465 6.34637 119.951 5.9387C119.439 5.53104 118.773 5.3272 117.953 5.32883C117.401 5.3134 116.855 5.44089 116.363 5.69752C115.927 5.93464 115.552 6.27328 115.266 6.68664C114.967 7.12841 114.751 7.62459 114.631 8.14839C114.49 8.73309 114.422 9.33403 114.426 9.93579C114.422 10.5351 114.494 11.1328 114.642 11.7134C114.77 12.2348 114.987 12.7286 115.282 13.1727C115.562 13.5869 115.935 13.9256 116.369 14.1618C116.852 14.4103 117.386 14.533 117.927 14.5192C118.829 14.5192 119.55 14.256 120.09 13.7307C120.63 13.2044 120.955 12.4995 121.065 11.616H122.275C122.152 12.8545 121.714 13.833 120.962 14.5509C120.21 15.2695 119.198 15.6302 117.927 15.6342C117.217 15.6496 116.514 15.5011 115.867 15.2022Z"
            fill={color}
          />
          <path
            d="M129.88 14.1823C130.327 13.9492 130.712 13.6106 131.008 13.1948C131.319 12.7507 131.549 12.2512 131.684 11.7218C131.985 10.5507 131.985 9.32039 131.684 8.14936C131.548 7.61988 131.319 7.12045 131.008 6.67624C130.712 6.26045 130.327 5.92181 129.88 5.68874C128.89 5.21043 127.743 5.21043 126.753 5.68874C126.309 5.92587 125.923 6.2637 125.624 6.67624C125.308 7.11882 125.074 7.61826 124.937 8.14936C124.636 9.32039 124.636 10.5507 124.937 11.7218C125.074 12.2529 125.307 12.7522 125.624 13.1948C126.251 14.0743 127.261 14.5787 128.322 14.5413C128.861 14.5518 129.394 14.4284 129.878 14.1823H129.881H129.88ZM126.258 15.2023C125.662 14.9246 125.133 14.5145 124.708 14.0037C124.273 13.471 123.941 12.8562 123.733 12.1944C123.282 10.7229 123.282 9.14498 123.733 7.67348C123.941 7.01163 124.273 6.39769 124.708 5.86415C125.132 5.35172 125.661 4.94243 126.258 4.66551C126.907 4.36991 127.609 4.22374 128.319 4.23673C129.025 4.22292 129.725 4.3691 130.369 4.66551C130.959 4.94569 131.482 5.35498 131.902 5.86415C132.337 6.39607 132.665 7.01082 132.867 7.67348C133.302 9.14741 133.302 10.7212 132.867 12.1944C132.665 12.857 132.337 13.4717 131.902 14.0037C131.482 14.5129 130.959 14.9222 130.369 15.2023C129.725 15.4987 129.025 15.6449 128.319 15.6311C127.609 15.6441 126.907 15.498 126.258 15.2023Z"
            fill={color}
          />
          <path
            d="M136.271 4.48653V6.12857H136.313C136.645 5.59097 137.086 5.13215 137.604 4.78213C138.125 4.4305 138.767 4.25509 139.533 4.2559C140.147 4.24453 140.753 4.41507 141.275 4.74884C141.795 5.09803 142.18 5.62265 142.362 6.23252H142.403C142.736 5.62589 143.223 5.12159 143.808 4.77077C144.418 4.41994 145.108 4.24291 145.808 4.25672C146.234 4.25346 146.658 4.32818 147.061 4.47679C147.441 4.61728 147.787 4.83979 148.077 5.12808C148.372 5.43018 148.603 5.79237 148.754 6.1911C148.924 6.654 149.007 7.14612 148.999 7.64149V15.358H147.746V7.74462C147.746 6.93173 147.536 6.33241 147.12 5.94586C146.674 5.5528 146.099 5.34816 145.511 5.37415C145.132 5.3709 144.756 5.44236 144.403 5.58447C144.065 5.72253 143.754 5.92555 143.491 6.18299C143.216 6.45585 143.002 6.78636 142.865 7.15181C142.711 7.5619 142.634 7.998 142.639 8.43815V15.3588H141.367V7.74462C141.367 6.93173 141.165 6.33241 140.763 5.94586C140.344 5.55687 139.795 5.3514 139.229 5.37415C138.847 5.37252 138.467 5.45129 138.115 5.60559C137.761 5.75827 137.438 5.97509 137.161 6.24714C136.878 6.52488 136.652 6.85702 136.495 7.2249C136.33 7.609 136.246 8.0248 136.249 8.44546V15.366H134.979V4.48653H136.271Z"
            fill={color}
          />
        </g>
      </svg>
    </Container>
  )
}
