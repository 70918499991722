import React, { FC, useEffect, useState } from 'react'
import {
  Container,
  Description,
  DropdownCaret,
  FakeCheckboxContainer,
  Header,
  InputContainer,
  InputRow,
  Title,
} from './AssignActorInput.styles'
import { CaretDown } from '../Icons/CaretDown'
import { Actor } from '../../pages/EnrollCompany/subpages/Directors'
import { getActorsDisplayName } from '../../methods/getActorFunctions'

interface CheckboxState {
  [key: string | number]: boolean
}

interface AssignActorInputProps {
  title: string
  onChange?: (state: CheckboxState) => void
  actors?: Actor[]
  initialCheckboxesState?: CheckboxState
}

const FakeCheckbox: FC<{ checked?: boolean }> = ({ checked }) => (
  <FakeCheckboxContainer checked={checked}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M2 6C2 3.79086 3.79086 2 6 2H14C16.2091 2 18 3.79086 18 6V14C18 16.2091 16.2091 18 14 18H6C3.79086 18 2 16.2091 2 14V6Z"
        fill="#0071F3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.6601 7.30444L9.4714 13.925L5.33008 10.819L6.53048 9.21843L9.1049 11.1492L13.0854 6.07031L14.6601 7.30444Z"
        fill="white"
      />
    </svg>

    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M2.5 6C2.5 4.067 4.067 2.5 6 2.5H14C15.933 2.5 17.5 4.067 17.5 6V14C17.5 15.933 15.933 17.5 14 17.5H6C4.067 17.5 2.5 15.933 2.5 14V6Z"
        stroke="#4F555F"
      />
    </svg>
  </FakeCheckboxContainer>
)

export const AssignActorInput: FC<AssignActorInputProps> = ({
  title,
  onChange,
  actors,
  initialCheckboxesState,
}) => {
  const [isOpen, setIsOpen] = useState(true)

  const [checkboxesState, setCheckboxesState] = useState<CheckboxState>({})

  useEffect(() => {
    setCheckboxesState(initialCheckboxesState)
  }, [initialCheckboxesState])

  const toggleCheckbox = (id: string | number) => {
    const newState = {
      ...checkboxesState,
      [id]: !checkboxesState[id],
    }

    setCheckboxesState(newState)
    onChange && onChange(newState)
  }

  return (
    <Container isOpen={isOpen} itemCount={actors.length}>
      <Header onClick={() => setIsOpen(!isOpen)}>
        {title}
        <DropdownCaret isOpen={isOpen}>
          <CaretDown />
        </DropdownCaret>
      </Header>

      <InputContainer>
        {actors?.map((actor) => (
          <InputRow
            key={actor.actorId}
            onClick={() => toggleCheckbox(actor.actorId)}
          >
            <FakeCheckbox checked={checkboxesState[actor.actorId]} />
            <Title>{getActorsDisplayName(actor)}</Title>
            {/* <Description>(Director - 20%)</Description> */}
          </InputRow>
        ))}
      </InputContainer>
    </Container>
  )
}
