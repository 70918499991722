import React, { useState } from 'react'
import { Container, Heading, IconWrapper, Content } from './LeftSideNav.styles'
import { HamburgerMenu } from '../../components/Icons/HamburgerMenu'
import { Xmark } from '../../components/Icons/Xmark'

interface LeftSideNavProps {
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  [x: string]: any
  children: React.ReactNode
  header?: string
  width?: string
  color?: string
  bgColor?: string
  border?: string
  fontFamily?: string
  padding?: string
}

export const LeftSideNav = ({
  header,
  children,
  width,
  color,
  bgColor,
  border,
  fontFamily,
  padding,
}: LeftSideNavProps) => {
  const [mobileOpened, setMobileOpened] = useState(false)
  return (
    <Container
      opened={mobileOpened}
      width={width}
      color={color}
      bgColor={bgColor}
      border={border}
      fontFamily={fontFamily}
      padding={padding}
    >
      {!mobileOpened && (
        <IconWrapper onClick={() => setMobileOpened(true)}>
          <HamburgerMenu size="24px" color={'#0071f3'} />
        </IconWrapper>
      )}
      {mobileOpened && (
        <IconWrapper onClick={() => setMobileOpened(false)}>
          <Xmark size="24px" color={'#0071f3'} />
        </IconWrapper>
      )}
      <Content bgColor={bgColor} border={border}>
        {header && (
          <Heading color={color} border={border}>
            {header}
          </Heading>
        )}
        {children}
      </Content>
    </Container>
  )
}
