import Cookies from 'js-cookie'
import { useEffect, useState } from 'react'

export const useAuth = () => {
  const [auth, setAuth] = useState<boolean>(false)

  useEffect(() => {
    const token = Cookies.get('authToken')
    setAuth(true)
  }, [])

  return auth
}
