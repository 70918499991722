import styled from 'styled-components'

interface ContainerProps {
  width?: string
  padding?: string
}

export const Container = styled.div<ContainerProps>`
  width: ${(props) => (props.width ? props.width : '')};
  aspect-ratio: 71 / 90;
  padding: ${(props) => (props.padding ? props.padding : '')};

  img {
    width: 100%;
    height: 100%;
  }
`
