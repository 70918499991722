import React, { useContext, useEffect, useMemo, useState } from 'react'
import { observer } from 'mobx-react'
import { PageHeading } from '../../../components/PageHeading/PageHeading'
import { StoreContext } from '../../../components/App'
import {
  AddNewUbosContainer,
  Boxed,
  Container,
  Button,
  ProceedButton,
} from './enroll.styles'
import IconPlus from '../../../assets/icon_plus_blue.svg'
import { AssignActorInput } from '../../../components/AssignActorInput/AssignActorInput'
import { Actor, blankActor, infoOnlyRequiredFields } from './Directors'
import { PersonCard } from '../../../components/PersonCard/PersonCard'
import { PersonCardAdd } from '../../../components/PersonCard/PersonCardAdd'
import { ToRight } from '../EnrollCompany.styles'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { ErrorInfo } from '../../../components/ErrorInfo/ErrorInfo'
import { toast } from 'react-toastify'

let nextId = 0

export const Ubos = observer(() => {
  const navigate = useNavigate()
  const store = useContext(StoreContext)
  const { theme } = store.AppState
  const {
    enrollCompanyPage: { ubos: trans },
  } = store.TranslationsState.translations

  const [isDisabled, setIsDisabled] = useState(false)
  const [isProceedDisabled, setIsProceedDisabled] = useState(false)
  const [people, setPeople] = useState<Actor[]>([])
  const [initialCheckboxesState, setInitialCheckboxesState] = useState<
    Record<string, boolean>
  >({})
  const [uboAsDirector, setUboAsDirector] = useState<Record<string, boolean>>(
    {}
  )

  const addDirectorToUbosHandler = (ids: Record<string, boolean>) => {
    setUboAsDirector(ids)
    console.log(ids)
  }

  const handleAddPerson = () => {
    setPeople([
      ...people,
      {
        ...blankActor,
        actorId: `new${nextId++}`,
        isDirector: false,
        isUbo: true,
        isNew: true,
      },
    ])
  }

  const handleDeletePerson = (id: string) => {
    console.log('delete')

    setPeople((prevItems) =>
      prevItems.filter((person) => person.actorId !== id)
    )
  }

  const handleChangePerson = (actor: Actor) => {
    console.log(actor)

    setPeople((prevItems) =>
      prevItems.map((person) =>
        person.actorId === actor.actorId ? actor : person
      )
    )
  }

  const handleProceed = async () => {
    try {
      setIsDisabled(true)

      await axios.post(
        `${process.env.WEB_API_URL}/merchant-enrolment/actors`,
        {
          edit: people
            .filter((person) => !person.isNew)
            .map((person) => {
              if (person.isApplicant) {
                return {
                  ...(person.actorId in uboAsDirector &&
                  uboAsDirector[person.actorId] !== person.isUbo
                    ? {
                        actorId: person.actorId,
                        isApplicant: person.isApplicant,
                        isDirector: person.isDirector,
                        isUbo: uboAsDirector[person.actorId],
                      }
                    : null),
                }
              } else {
                return {
                  ...person,
                  info: {
                    ...(person.info ? person.info : blankActor.info),
                    infix: person.info?.infix || '',
                    dateOfBirth:
                      person.info?.dateOfBirth ||
                      person.providerInfo?.dateOfBirth ||
                      0,
                  },
                  isUbo:
                    person.actorId in uboAsDirector
                      ? uboAsDirector[person.actorId]
                      : person.isUbo,
                }
              }
            })
            .filter((person) => person.actorId),
          add: people
            .filter(
              (person) =>
                person.isNew &&
                Object.values(infoOnlyRequiredFields(person.info)).every(
                  (x) => x !== null && x !== '' && x !== 0
                )
            )
            .map((person) => {
              return {
                ...Object.fromEntries(
                  Object.entries(person).filter(
                    ([key, value]) => key !== 'actorId' && key !== 'isNew'
                  )
                ),
                info: {
                  ...person.info,
                  infix: person.info?.infix || '',
                },
              }
            }),
        },
        {
          withCredentials: true,
        }
      )

      setIsDisabled(false)
      navigate('/enroll-company/summary')
    } catch (e) {
      if (axios.isAxiosError(e) && e.response) {
        toast(e.response.data.message, { type: 'error' })
      }
      setIsDisabled(false)
      console.log(e)
    }
  }

  const getActors = async () => {
    try {
      setIsDisabled(true)
      const res = await axios.get(
        `${process.env.WEB_API_URL}/merchant-enrolment/actors`,
        { withCredentials: true }
      )

      if (res.data) {
        setPeople(res.data.actors)
        setInitialCheckboxesState(
          res.data.actors
            ?.filter((person) => person.isDirector)
            .reduce((acc, actor) => {
              acc[actor.actorId] = actor.isUbo
              return acc
            }, {} as Record<string, boolean>)
        )
      }

      setIsDisabled(false)
    } catch (e) {
      setIsDisabled(false)
      console.log(e)
    }
  }

  useEffect(() => {
    getActors()
  }, [])

  useEffect(() => {
    setIsProceedDisabled(true)
    const isRequiredDataSet = people
      .filter((person) => person.isUbo)
      .every((person) =>
        Object.values(infoOnlyRequiredFields(person.info)).every(
          (x) => x !== null && x !== '' && x !== 0
        )
      )

    if (isRequiredDataSet) setIsProceedDisabled(false)
  }, [people])
  console.log(theme.button.borderRadius)
  return (
    <Container>
      <PageHeading
        title={trans.title}
        subtitle={trans.subtitle}
        {...theme.pageHeading}
      />
      <Boxed>
        {people
          .filter(
            (person) =>
              !person.isApplicant &&
              !person.isDirector &&
              person.isUbo &&
              !person.isNew
          )
          .map((person) => (
            <PersonCard
              key={person.actorId}
              person={person}
              onChange={handleChangePerson}
              onDelete={handleDeletePerson}
              initialEdit={true}
              isLoading={isDisabled}
            />
          ))}

        {people
          .filter((person) => person.isNew)
          .map((person) => (
            <PersonCardAdd
              key={person.actorId}
              person={person}
              onChange={handleChangePerson}
              onDelete={handleDeletePerson}
              isLoading={isDisabled}
            />
          ))}

        <AssignActorInput
          title={trans.assignDirectorAsUbo}
          actors={people.filter((person) => person.isDirector)}
          initialCheckboxesState={initialCheckboxesState}
          onChange={addDirectorToUbosHandler}
        />

        <AddNewUbosContainer>
          <h5>{trans.addNewUbo}</h5>
          <p>{trans.clickHereToAddNewUbo}</p>
          <Button onClick={handleAddPerson} {...theme.button}>
            <img src={IconPlus} alt="Add" />
            {trans.addNewUbo}
          </Button>
        </AddNewUbosContainer>

        <ToRight>
          <ProceedButton
            onClick={handleProceed}
            disabled={isProceedDisabled}
            {...theme.proceedButton}
          >
            <span>{trans.proceed}</span>
          </ProceedButton>
        </ToRight>
      </Boxed>
    </Container>
  )
})
