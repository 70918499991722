import React from 'react'
import { Container } from './Button.styles'

interface ButtonProps {
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  [x: string]: any
  children: React.ReactNode
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  className?: any
  bgColor?: string
  padding?: string
  paddingMobile?: string
  borderRadius?: string
  minWidth?: string
  width?: string
  widthMobile?: string
  height?: string
  heightMobile?: string
  border?: string
  boxShadow?: string
  margin?: string
  marginMobile?: string
  color?: string
  colorHover?: string
  bgColorHover?: string
  borderHover?: string
  bgColorDisabled?: string
  borderDisabled?: string
  colorDisabled?: string
  disabled?: boolean
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick?: (props: any) => any
  fontSize?: string
  fontSizeMobile?: string
  fontWeight?: string
  justifyContent?: string
  breakpointMobile?: string
  textDecoration?: string
  cursor?: string
  cursorDisabled?: string
  animatable?: boolean
  position?: string
  top?: string
  right?: string
  bottom?: string
  left?: string
}

export const Button = ({
  children,
  className,
  bgColor,
  padding,
  paddingMobile,
  borderRadius,
  minWidth,
  width,
  widthMobile,
  height,
  heightMobile,
  border,
  boxShadow,
  margin,
  marginMobile,
  color,
  colorHover,
  bgColorHover,
  borderHover,
  bgColorDisabled,
  borderDisabled,
  colorDisabled,
  disabled,
  onClick,
  fontSize,
  fontSizeMobile,
  fontWeight,
  justifyContent,
  textDecoration,
  cursor,
  cursorDisabled,
  animatable,
}: ButtonProps) => {
  function handleOnClick(e) {
    onClick && onClick(e)
  }

  return (
    <Container
      bgColor={bgColor}
      padding={padding}
      paddingMobile={paddingMobile}
      borderRadius={borderRadius}
      minWidth={minWidth}
      width={width}
      widthMobile={widthMobile}
      height={height}
      heightMobile={heightMobile}
      border={border}
      boxShadow={boxShadow}
      margin={margin}
      marginMobile={marginMobile}
      color={color}
      colorHover={colorHover}
      bgColorHover={bgColorHover}
      borderHover={borderHover}
      disabled={disabled}
      onClick={(e) => handleOnClick(e)}
      bgColorDisabled={bgColorDisabled}
      colorDisabled={colorDisabled}
      borderDisabled={borderDisabled}
      fontSize={fontSize}
      fontSizeMobile={fontSizeMobile}
      fontWeight={fontWeight}
      justifyContent={justifyContent}
      textDecoration={textDecoration}
      cursor={cursor}
      cursorDisabled={cursorDisabled}
      animatable={animatable}
      className={className ? className : ''}
    >
      {children}
    </Container>
  )
}
