import styled from 'styled-components'

interface InputProps {
  bgColor?: string
  padding?: string
  borderRadius?: string
  border?: string
  color?: string
  borderColorFocus?: string
}

export const StyledTextInput = styled.input<InputProps>`
  width: 100%;
  min-width: 114px;
  height: 35px;
  font-family: 'Inter';
  padding: ${(props) => (props.padding ? props.padding : '6px 6px 6px 12px')};
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : '5px'};
  border: ${(props) => (props.border ? props.border : '1px solid #c6c3c3')};
  background-color: inherit;
  color: ${(props) => (props.color ? props.color : '#1c1c1c')};

  &:focus {
    border-color: ${(props) =>
      props.borderColorFocus ? props.borderColorFocus : '#0071F3'} !important;
    outline: none;
  }

  &:disabled {
    border-color: #e6e6e6 !important;
    background-color: #f5f4f3 !important;
    color: #1c1c1c !important;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:focus {
    transition: background-color 0s 600000s, color 0s 600000s !important;
  }
`
