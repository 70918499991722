import styled from 'styled-components'

interface SearchInputProps {
  className?: string
  disabled?: string
  type?: 'button' | 'reset' | 'submit'
}

export const SearchInput = styled.input.attrs<SearchInputProps>((props) => ({
  className: `sc-search-global ${props.className}`,
}))`
  font-family: 'Inter';
  border-radius: 6px;
  border: 1px solid #eae3f8;
  background-color: #fff;
  outline: none;
  padding: 8px 11px;
  margin-bottom: 32px;
`
