import React, { useContext, useEffect, useState } from 'react'
import { StoreContext } from '../../../components/App'
import { PageHeading } from '../../../components/PageHeading/PageHeading'
import { PersonalData } from './summary/PersonalData'
import { ToRight } from '../EnrollCompany.styles'
import {
  Boxed,
  Container,
  ProceedButton,
  AbsoluteStatusBadge,
} from './enroll.styles'
import { CompanyData } from './summary/CompanyData'
import { Directors } from './summary/Directors'
import { Ubos } from './summary/Ubos'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { StatusInfo } from '../../../components/StatusInfo/StatusInfo'

const statusMapping = {
  pending: 'notice',
  errored: 'negative',
  completed: 'positive',
}

export const Summary = () => {
  const navigate = useNavigate()
  const store = useContext(StoreContext)
  const { theme } = store.AppState
  const {
    enrollCompanyPage: { summary: trans },
  } = store.TranslationsState.translations
  const [status, setStatus] = useState({ value: '', label: '' })
  const [isPolling, setIsPolling] = useState(true)

  const getStatus = async () => {
    try {
      const res = await axios.get(
        `${process.env.WEB_API_URL}/merchant-enrolment/merchants/status`,
        { withCredentials: true }
      )

      if (res.data && res.data.status) {
        setStatus({ value: res.data.status, label: trans[res.data.status] })

        if (res.data.status === 'completed') {
          setIsPolling(false)
        }
      }
    } catch (e) {
      console.log(e)
    }
  }

  const handleProceed = () => {
    navigate('/enroll-company/success')
  }

  useEffect(() => {
    getStatus()
  }, [])

  useEffect(() => {
    if (isPolling) {
      const intervalId = setInterval(getStatus, 30000) // Poll every 30 seconds

      // Cleanup on unmount or when stopping polling
      return () => clearInterval(intervalId)
    }
  }, [isPolling])

  return (
    <Container>
      <PageHeading
        title={trans.title}
        subtitle={trans.subtitle}
        {...theme.pageHeading}
      />
      {status.value && (
        <AbsoluteStatusBadge>
          <StatusInfo status={statusMapping[status.value]}>
            {status.label}
          </StatusInfo>
        </AbsoluteStatusBadge>
      )}
      <Boxed>
        <PageHeading
          title={trans.personalData}
          {...theme.pageHeading}
          headerFontSize={18}
          margin="0"
        />
        <PersonalData initialEdit={false} />

        <PageHeading
          title={trans.companyData}
          {...theme.pageHeading}
          headerFontSize={18}
          margin="0"
        />
        <CompanyData initialEdit={false} />

        <PageHeading
          title={trans.directors}
          {...theme.pageHeading}
          headerFontSize={18}
          margin="0"
        />
        <Directors initialEdit={false} />

        <PageHeading
          title={trans.ubos}
          {...theme.pageHeading}
          headerFontSize={18}
          margin="0"
        />
        <Ubos initialEdit={false} />

        {/* <ToRight>
          <ProceedButton onClick={handleProceed}>
            <span>{trans.continue}</span>
          </ProceedButton>
        </ToRight> */}
      </Boxed>
    </Container>
  )
}
