import React, { useContext, useState } from 'react'
import { observer } from 'mobx-react'
import arrow_right from '../../../assets/arrow_right.svg'
import icon_info from '../../../assets/icon_info.svg'
import logo_electronicID from '../../../assets/logo_electronicID.svg'
import logo_iDIN from '../../../assets/logo_iDIN.svg'
import { StoreContext } from '../../../components/App'
import {
  Button,
  Container,
  Header,
  Panel,
  PanelContent,
  PanelInfo,
  PanelList,
  PanelNavigation,
  PanelThumbnail,
  SubHeader,
  ToRight,
} from '../EnrollCompany.styles'
import { LoadingSpinner } from '../../../components/LoadingSpinner/LoadingSpinner'

export const IdentityVerification = observer(() => {
  const store = useContext(StoreContext)
  const { theme } = store.AppState
  const {
    enrollCompanyPage: { identityVerification: trans },
  } = store.TranslationsState.translations

  const [current, setCurrent] = useState('')
  const [isDisabled, setIsDisabled] = useState(false)

  function handleCurrent(value: 'idin' | 'eidv') {
    if (!isDisabled) {
      if (value === current) {
        setCurrent('')
      } else {
        setCurrent(value)
      }
    }
  }

  function handleRedirect() {
    if (current.length > 0) {
      setIsDisabled(true)
      window.location.href = `${process.env.WEB_API_URL}/merchant-enrolment/ident-start/${current}`
    }
  }

  return (
    <Container>
      <Header>{trans.title}</Header>
      <SubHeader>{trans.chooseIdentityVerificationMethod}</SubHeader>

      {isDisabled ? (
        <LoadingSpinner
          {...theme.loadingSpinner}
          height="278px"
          width="48px"
          bgColor="#fff"
          primary="#0071f3"
        />
      ) : (
        <PanelList>
          <Panel
            className={`${current === 'idin' ? 'current' : ''} ${
              isDisabled ? 'disabled' : ''
            }`}
            onClick={() => handleCurrent('idin')}
          >
            <PanelThumbnail src={logo_iDIN} alt="iDIN" />
            <PanelContent>
              <h3>iDIN</h3>
              <span>{trans.netherlands}</span>
            </PanelContent>
            <PanelNavigation src={arrow_right} alt="GoTo" />
          </Panel>
          <Panel
            className={`${current === 'eidv' ? 'current' : ''} ${
              isDisabled ? 'disabled' : ''
            }`}
            onClick={() => handleCurrent('eidv')}
          >
            <PanelThumbnail src={logo_electronicID} alt="ElectronicID" />
            <PanelContent>
              <h3>
                ElectronicID <PanelInfo src={icon_info} alt="info" />
              </h3>
              <span>{trans.videoIdentityVerification}</span>
            </PanelContent>
            <PanelNavigation src={arrow_right} alt="GoTo" />
          </Panel>
        </PanelList>
      )}
      <ToRight>
        <Button
          type="button"
          onClick={() => handleRedirect()}
          disabled={isDisabled}
        >
          {trans.continue}
        </Button>
      </ToRight>
    </Container>
  )
})
