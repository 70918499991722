import React from 'react'
import { Icon, IconProps } from './Icon.styles'

export const SortIcon = ({ size, color, margin }: IconProps) => {
  return (
    <Icon size={size} margin={margin}>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="sort">
          <path
            id="Vector"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.00033 6.17904L3.08952 8.08984C2.764 8.41536 2.23665 8.41536 1.91113 8.08984C1.58561 7.76432 1.58561 7.23698 1.91113 6.91146L5.24447 3.57812C5.37788 3.44472 5.54519 3.36598 5.71861 3.34192C5.75623 3.33669 5.79464 3.33398 5.83366 3.33398C5.87268 3.33398 5.91109 3.33669 5.94871 3.34192C6.12213 3.36598 6.28944 3.44472 6.42285 3.57812L9.75618 6.91146C10.0817 7.23698 10.0817 7.76432 9.75618 8.08984C9.43066 8.41536 8.90332 8.41536 8.5778 8.08984L6.66699 6.17904V15.834C6.66699 16.293 6.29264 16.6673 5.83366 16.6673C5.37467 16.6673 5.00033 16.293 5.00033 15.834V6.17904ZM15.0003 13.8223L16.9111 11.9115C17.2367 11.5859 17.764 11.5859 18.0895 11.9115C18.415 12.237 18.415 12.7643 18.0895 13.0898L14.7562 16.4232C14.7051 16.4743 14.6477 16.5181 14.586 16.5539C14.4628 16.626 14.3196 16.6673 14.167 16.6673C14.0144 16.6673 13.8712 16.626 13.748 16.5539C13.6863 16.5181 13.6289 16.4743 13.5778 16.4232L10.2445 13.0898C9.91895 12.7643 9.91895 12.237 10.2445 11.9115C10.57 11.5859 11.0973 11.5859 11.4229 11.9115L13.3337 13.8223V4.16732C13.3337 3.70833 13.708 3.33398 14.167 3.33398C14.626 3.33398 15.0003 3.70833 15.0003 4.16732V13.8223Z"
            fill={color ? color : '#272142'}
          />
        </g>
      </svg>
    </Icon>
  )
}
