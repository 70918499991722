export const countryList = [
  {
    value: 'AND',
    label: 'Andorra',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/1/19/Flag_of_Andorra.svg',
  },
  {
    value: 'ARE',
    label: 'United Arab Emirates',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/c/cb/Flag_of_the_United_Arab_Emirates.svg',
  },
  {
    value: 'AFG',
    label: 'Afghanistan',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/5/5c/Flag_of_the_Taliban.svg',
  },
  {
    value: 'ATG',
    label: 'Antigua and Barbuda',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/8/89/Flag_of_Antigua_and_Barbuda.svg',
  },
  {
    value: 'AIA',
    label: 'Anguilla',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/b/b4/Flag_of_Anguilla.svg',
  },
  {
    value: 'ALB',
    label: 'Albania',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/3/36/Flag_of_Albania.svg',
  },
  {
    value: 'ARM',
    label: 'Armenia',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/2/2f/Flag_of_Armenia.svg',
  },
  {
    value: 'AGO',
    label: 'Angola',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/9/9d/Flag_of_Angola.svg',
  },
  {
    value: 'ARG',
    label: 'Argentina',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/1/1a/Flag_of_Argentina.svg',
  },
  {
    value: 'ASM',
    label: 'American Samoa',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/8/87/Flag_of_American_Samoa.svg',
  },
  {
    value: 'AUT',
    label: 'Austria',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/4/41/Flag_of_Austria.svg',
  },
  {
    value: 'AUS',
    label: 'Australia',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/8/88/Flag_of_Australia_(converted).svg',
  },
  {
    value: 'ARU',
    label: 'Aruba',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/f/f6/Flag_of_Aruba.svg',
  },
  {
    value: 'ALA',
    label: 'Åland Islands',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/5/52/Flag_of_%C3%85land.svg',
  },
  {
    value: 'AZE',
    label: 'Azerbaijan',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/d/dd/Flag_of_Azerbaijan.svg',
  },
  {
    value: 'BIH',
    label: 'Bosnia and Herzegovina',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/b/bf/Flag_of_Bosnia_and_Herzegovina.svg',
  },
  {
    value: 'BRB',
    label: 'Barbados',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/e/ef/Flag_of_Barbados.svg',
  },
  {
    value: 'BGD',
    label: 'Bangladesh',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/f/f9/Flag_of_Bangladesh.svg',
  },
  {
    value: 'BEL',
    label: 'Belgium',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/6/65/Flag_of_Belgium.svg',
  },
  {
    value: 'BFA',
    label: 'Burkina Faso',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/3/31/Flag_of_Burkina_Faso.svg',
  },
  {
    value: 'BGR',
    label: 'Bulgaria',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/9/9a/Flag_of_Bulgaria.svg',
  },
  {
    value: 'BHR',
    label: 'Bahrain',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/2/2c/Flag_of_Bahrain.svg',
  },
  {
    value: 'BDI',
    label: 'Burundi',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/5/50/Flag_of_Burundi.svg',
  },
  {
    value: 'BEN',
    label: 'Benin',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/0/0a/Flag_of_Benin.svg',
  },
  {
    value: 'BLM',
    label: 'Saint Barthélemy',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/0/03/Saint-Barthelémy_Icône.svg',
  },
  {
    value: 'BMU',
    label: 'Bermuda',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/b/bf/Flag_of_Bermuda.svg',
  },
  {
    value: 'BRN',
    label: 'Brunei Darussalam',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/9/9c/Flag_of_Brunei.svg',
  },
  {
    value: 'BOL',
    label: 'Bolivia, Plurinational State of',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/5/5b/Bolivia_Flag.svg',
  },
  {
    value: 'BES',
    label: 'Bonaire, Sint Eustatius and Saba',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/2/20/Flag_of_the_Netherlands.svg',
  },
  {
    value: 'BRA',
    label: 'Brazil',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/0/05/Flag_of_Brazil.svg',
  },
  {
    value: 'BHS',
    label: 'Bahamas',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/9/93/Flag_of_the_Bahamas.svg',
  },
  {
    value: 'BTN',
    label: 'Bhutan',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/9/91/Flag_of_Bhutan.svg',
  },
  {
    value: 'BWA',
    label: 'Botswana',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/f/fa/Flag_of_Botswana.svg',
  },
  {
    value: 'BLR',
    label: 'Belarus',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/8/85/Flag_of_Belarus.svg',
  },
  {
    value: 'BLZ',
    label: 'Belize',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/e/e7/Flag_of_Belize.svg',
  },
  {
    value: 'CAN',
    label: 'Canada',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/d/d9/Flag_of_Canada_(Pantone).svg',
  },
  {
    value: 'CCK',
    label: 'Cocos (Keeling) Islands',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/7/74/Flag_of_the_Cocos_(Keeling)_Islands.svg',
  },
  {
    value: 'COD',
    label: 'Congo, Democratic Republic of the',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/1/11/Flag_of_the_Democratic_Republic_of_the_Congo_(3-2).svg',
  },
  {
    value: 'CAF',
    label: 'Central African Republic',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/6/6f/Flag_of_the_Central_African_Republic.svg',
  },
  {
    value: 'COG',
    label: 'Congo',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/9/92/Flag_of_the_Republic_of_the_Congo.svg',
  },
  {
    value: 'CHE',
    label: 'Switzerland',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/f/f3/Flag_of_Switzerland.svg',
  },

  {
    value: 'CIV',
    label: "Côte d'Ivoire",
    icon: 'https://upload.wikimedia.org/wikipedia/commons/f/fe/Flag_of_Côte_d%27Ivoire.svg',
  },
  {
    value: 'COK',
    label: 'Cook Islands',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/3/35/Flag_of_the_Cook_Islands.svg',
  },
  {
    value: 'CHL',
    label: 'Chile',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/7/78/Flag_of_Chile.svg',
  },
  {
    value: 'CMR',
    label: 'Cameroon',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/4/4f/Flag_of_Cameroon.svg',
  },
  {
    value: 'CHN',
    label: 'China',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/f/fa/Flag_of_the_People%27s_Republic_of_China.svg',
  },
  {
    value: 'COL',
    label: 'Colombia',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/2/21/Flag_of_Colombia.svg',
  },
  {
    value: 'CRI',
    label: 'Costa Rica',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/b/bc/Flag_of_Costa_Rica_(state).svg',
  },
  {
    value: 'CUB',
    label: 'Cuba',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/b/bd/Flag_of_Cuba.svg',
  },
  {
    value: 'CPV',
    label: 'Cabo Verde',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/3/38/Flag_of_Cape_Verde.svg',
  },
  {
    value: 'CUW',
    label: 'Curaçao',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/b/b1/Flag_of_Curaçao.svg',
  },
  {
    value: 'CXR',
    label: 'Christmas Island',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/6/67/Flag_of_Christmas_Island.svg',
  },
  {
    value: 'CYP',
    label: 'Cyprus',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/d/d4/Flag_of_Cyprus.svg',
  },
  {
    value: 'CZE',
    label: 'Czechia',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/c/cb/Flag_of_the_Czech_Republic.svg',
  },
  {
    value: 'DEU',
    label: 'Germany',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/b/ba/Flag_of_Germany.svg',
  },
  {
    value: 'DJI',
    label: 'Djibouti',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/3/34/Flag_of_Djibouti.svg',
  },
  {
    value: 'DNK',
    label: 'Denmark',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/9/9c/Flag_of_Denmark.svg',
  },
  {
    value: 'DMA',
    label: 'Dominica',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/c/c4/Flag_of_Dominica.svg',
  },
  {
    value: 'DOM',
    label: 'Dominican Republic',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/9/9f/Flag_of_the_Dominican_Republic.svg',
  },
  {
    value: 'DZA',
    label: 'Algeria',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/7/77/Flag_of_Algeria.svg',
  },
  {
    value: 'ECU',
    label: 'Ecuador',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/e/e8/Flag_of_Ecuador.svg',
  },
  {
    value: 'EST',
    label: 'Estonia',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/8/8f/Flag_of_Estonia.svg',
  },
  {
    value: 'EGY',
    label: 'Egypt',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/f/fe/Flag_of_Egypt.svg',
  },
  {
    value: 'ESH',
    label: 'Western Sahara',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/2/26/Flag_of_the_Sahrawi_Arab_Democratic_Republic.svg',
  },
  {
    value: 'ERI',
    label: 'Eritrea',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/2/29/Flag_of_Eritrea.svg',
  },
  {
    value: 'ESP',
    label: 'Spain',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/9/9a/Flag_of_Spain.svg',
  },
  {
    value: 'ETH',
    label: 'Ethiopia',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/7/71/Flag_of_Ethiopia.svg',
  },
  {
    value: 'FIN',
    label: 'Finland',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/b/bc/Flag_of_Finland.svg',
  },
  {
    value: 'FJI',
    label: 'Fiji',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/b/ba/Flag_of_Fiji.svg',
  },
  {
    value: 'FLK',
    label: 'Falkland Islands (Malvinas)',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/8/83/Flag_of_the_Falkland_Islands.svg',
  },
  {
    value: 'FSM',
    label: 'Micronesia, Federated States of',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/e/e4/Flag_of_the_Federated_States_of_Micronesia.svg',
  },
  {
    value: 'FRO',
    label: 'Faroe Islands',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/3/3c/Flag_of_the_Faroe_Islands.svg',
  },
  {
    value: 'FRA',
    label: 'France',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/c/c3/Flag_of_France.svg',
  },
  {
    value: 'GAB',
    label: 'Gabon',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/0/04/Flag_of_Gabon.svg',
  },
  {
    value: 'GBR',
    label: 'United Kingdom of Great Britain and Northern Ireland',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/a/a5/Flag_of_the_United_Kingdom_(1-2).svg',
  },
  {
    value: 'GRD',
    label: 'Grenada',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/b/bc/Flag_of_Grenada.svg',
  },
  {
    value: 'GEO',
    label: 'Georgia',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/0/0f/Flag_of_Georgia.svg',
  },
  {
    value: 'GUF',
    label: 'French Guiana',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/e/ed/Flag_of_France_%28Pantone%29.svg',
  },
  {
    value: 'GGY',
    label: 'Guernsey',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/f/fa/Flag_of_Guernsey.svg',
  },
  {
    value: 'GHA',
    label: 'Ghana',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/1/19/Flag_of_Ghana.svg',
  },
  {
    value: 'GIB',
    label: 'Gibraltar',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/0/02/Flag_of_Gibraltar.svg',
  },
  {
    value: 'GRL',
    label: 'Greenland',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/0/09/Flag_of_Greenland.svg',
  },
  {
    value: 'GMB',
    label: 'Gambia',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/7/77/Flag_of_The_Gambia.svg',
  },
  {
    value: 'GIN',
    label: 'Guinea',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/e/ed/Flag_of_Guinea.svg',
  },
  {
    value: 'GLP',
    label: 'Guadeloupe',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/9/9f/Flag_of_France_%287x10%29.svg',
  },
  {
    value: 'GNQ',
    label: 'Equatorial Guinea',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/3/31/Flag_of_Equatorial_Guinea.svg',
  },
  {
    value: 'GRC',
    label: 'Greece',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/5/5c/Flag_of_Greece.svg',
  },
  {
    value: 'SGS',
    label: 'South Georgia and the South Sandwich Islands',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/e/ed/Flag_of_South_Georgia_and_the_South_Sandwich_Islands.svg',
  },
  {
    value: 'GTM',
    label: 'Guatemala',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/e/ec/Flag_of_Guatemala.svg',
  },
  {
    value: 'GUM',
    label: 'Guam',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/0/07/Flag_of_Guam.svg',
  },
  {
    value: 'GNB',
    label: 'Guinea-Bissau',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/0/01/Flag_of_Guinea-Bissau.svg',
  },
  {
    value: 'GUY',
    label: 'Guyana',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/9/99/Flag_of_Guyana.svg',
  },
  {
    value: 'HKG',
    label: 'Hong Kong',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/5/5b/Flag_of_Hong_Kong.svg',
  },
  {
    value: 'HND',
    label: 'Honduras',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/8/82/Flag_of_Honduras.svg',
  },
  {
    value: 'HRV',
    label: 'Croatia',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/1/1b/Flag_of_Croatia.svg',
  },
  {
    value: 'HTI',
    label: 'Haiti',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/5/56/Flag_of_Haiti.svg',
  },
  {
    value: 'HUN',
    label: 'Hungary',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/c/c1/Flag_of_Hungary.svg',
  },
  {
    value: 'IDN',
    label: 'Indonesia',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/9/9f/Flag_of_Indonesia.svg',
  },
  {
    value: 'IRL',
    label: 'Ireland',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/4/45/Flag_of_Ireland.svg',
  },
  {
    value: 'ISR',
    label: 'Israel',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/d/d4/Flag_of_Israel.svg',
  },
  {
    value: 'IMN',
    label: 'Isle of Man',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/b/bc/Flag_of_the_Isle_of_Man.svg',
  },
  {
    value: 'IND',
    label: 'India',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/4/41/Flag_of_India.svg',
  },
  {
    value: 'IRQ',
    label: 'Iraq',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/f/f6/Flag_of_Iraq.svg',
  },
  {
    value: 'IRN',
    label: 'Iran, Islamic Republic of',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/c/ca/Flag_of_Iran.svg',
  },
  {
    value: 'ISL',
    label: 'Iceland',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/c/ce/Flag_of_Iceland.svg',
  },
  {
    value: 'ITA',
    label: 'Italy',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/0/03/Flag_of_Italy.svg',
  },
  {
    value: 'JEY',
    label: 'Jersey',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/1/1c/Flag_of_Jersey.svg',
  },
  {
    value: 'JAM',
    label: 'Jamaica',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/0/0a/Flag_of_Jamaica.svg',
  },
  {
    value: 'JOR',
    label: 'Jordan',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/c/c0/Flag_of_Jordan.svg',
  },
  {
    value: 'JPN',
    label: 'Japan',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/b/bc/Flag_of_Japan%28bordered%29.svg',
  },
  {
    value: 'KEN',
    label: 'Kenya',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/4/49/Flag_of_Kenya.svg',
  },
  {
    value: 'KGZ',
    label: 'Kyrgyzstan',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/c/c7/Flag_of_Kyrgyzstan.svg',
  },
  {
    value: 'KHM',
    label: 'Cambodia',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/8/83/Flag_of_Cambodia.svg',
  },
  {
    value: 'KIR',
    label: 'Kiribati',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/d/d3/Flag_of_Kiribati.svg',
  },
  {
    value: 'COM',
    label: 'Comoros',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/d/df/Flag_of_the_Comoros_(3-2).svg',
  },
  {
    value: 'KNA',
    label: 'Saint Kitts and Nevis',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/f/fe/Flag_of_Saint_Kitts_and_Nevis.svg',
  },
  {
    value: 'PRK',
    label: "Korea, Democratic People's Republic of",
    icon: 'https://upload.wikimedia.org/wikipedia/commons/5/51/Flag_of_North_Korea.svg',
  },
  {
    value: 'KOR',
    label: 'Korea, Republic of',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/0/09/Flag_of_South_Korea.svg',
  },
  {
    value: 'KWT',
    label: 'Kuwait',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/a/aa/Flag_of_Kuwait.svg',
  },
  {
    value: 'CYM',
    label: 'Cayman Islands',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/0/0f/Flag_of_the_Cayman_Islands.svg',
  },
  {
    value: 'KAZ',
    label: 'Kazakhstan',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/d/d3/Flag_of_Kazakhstan.svg',
  },
  {
    value: 'LAO',
    label: "Lao People's Democratic Republic",
    icon: 'https://upload.wikimedia.org/wikipedia/commons/5/56/Flag_of_Laos.svg',
  },
  {
    value: 'LBN',
    label: 'Lebanon',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/5/59/Flag_of_Lebanon.svg',
  },
  {
    value: 'LCA',
    label: 'Saint Lucia',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/9/9f/Flag_of_Saint_Lucia.svg',
  },
  {
    value: 'LIE',
    label: 'Liechtenstein',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/4/47/Flag_of_Liechtenstein.svg',
  },
  {
    value: 'LKA',
    label: 'Sri Lanka',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/1/11/Flag_of_Sri_Lanka.svg',
  },
  {
    value: 'LBR',
    label: 'Liberia',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/b/b8/Flag_of_Liberia.svg',
  },
  {
    value: 'LSO',
    label: 'Lesotho',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/4/4a/Flag_of_Lesotho.svg',
  },
  {
    value: 'LTU',
    label: 'Lithuania',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/1/11/Flag_of_Lithuania.svg',
  },
  {
    value: 'LUX',
    label: 'Luxembourg',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/d/da/Flag_of_Luxembourg.svg',
  },
  {
    value: 'LVA',
    label: 'Latvia',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/8/84/Flag_of_Latvia.svg',
  },
  {
    value: 'LBY',
    label: 'Libya',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/0/05/Flag_of_Libya.svg',
  },
  {
    value: 'MAR',
    label: 'Morocco',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/2/2c/Flag_of_Morocco.svg',
  },
  {
    value: 'MCO',
    label: 'Monaco',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/e/ea/Flag_of_Monaco.svg',
  },
  {
    value: 'MDA',
    label: 'Moldova, Republic of',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/2/27/Flag_of_Moldova.svg',
  },
  {
    value: 'MNE',
    label: 'Montenegro',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/6/64/Flag_of_Montenegro.svg',
  },
  {
    value: 'MTQ',
    label: 'Saint Martin, (French part)',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/d/dd/Flag_of_Saint-Martin_%28fictional%29.svg',
  },
  {
    value: 'MDG',
    label: 'Madagascar',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/b/bc/Flag_of_Madagascar.svg',
  },
  {
    value: 'MHL',
    label: 'Marshall Islands',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/2/2e/Flag_of_the_Marshall_Islands.svg',
  },
  {
    value: 'MKD',
    label: 'North Macedonia',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/7/79/Flag_of_North_Macedonia.svg',
  },
  {
    value: 'MLI',
    label: 'Mali',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/9/92/Flag_of_Mali.svg',
  },
  {
    value: 'MMR',
    label: 'Myanmar',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/8/8c/Flag_of_Myanmar.svg',
  },
  {
    value: 'MNG',
    label: 'Mongolia',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/4/4c/Flag_of_Mongolia.svg',
  },
  {
    value: 'MAC',
    label: 'Macao',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/6/63/Flag_of_Macau.svg',
  },
  {
    value: 'MNP',
    label: 'Northern Mariana Islands',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/e/e0/Flag_of_the_Northern_Mariana_Islands.svg',
  },
  {
    value: 'MTQ',
    label: 'Martinique',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/2/21/Flag_of_the_Territorial_Collectivity_of_Martinique.svg',
  },
  {
    value: 'MRT',
    label: 'Mauritania',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/4/43/Flag_of_Mauritania.svg',
  },
  {
    value: 'MSR',
    label: 'Montserrat',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/d/d0/Flag_of_Montserrat.svg',
  },
  {
    value: 'MLT',
    label: 'Malta',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/7/73/Flag_of_Malta.svg',
  },
  {
    value: 'MUS',
    label: 'Mauritius',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/7/77/Flag_of_Mauritius.svg',
  },
  {
    value: 'MDV',
    label: 'Maldives',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/0/0f/Flag_of_Maldives.svg',
  },
  {
    value: 'MWI',
    label: 'Malawi',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/d/d1/Flag_of_Malawi.svg',
  },
  {
    value: 'MEX',
    label: 'Mexico',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/f/fc/Flag_of_Mexico.svg',
  },
  {
    value: 'MYS',
    label: 'Malaysia',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/6/66/Flag_of_Malaysia.svg',
  },
  {
    value: 'MOZ',
    label: 'Mozambique',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/d/d0/Flag_of_Mozambique.svg',
  },
  {
    value: 'NAM',
    label: 'Namibia',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/0/00/Flag_of_Namibia.svg',
  },
  {
    value: 'NCL',
    label: 'New Caledonia',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/6/66/Flag_of_FLNKS.svg',
  },
  {
    value: 'NER',
    label: 'Niger',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/f/f4/Flag_of_Niger.svg',
  },
  {
    value: 'NFK',
    label: 'Norfolk Island',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/4/48/Flag_of_Norfolk_Island.svg',
  },
  {
    value: 'NGA',
    label: 'Nigeria',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/7/79/Flag_of_Nigeria.svg',
  },
  {
    value: 'NIC',
    label: 'Nicaragua',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/1/19/Flag_of_Nicaragua.svg',
  },
  {
    value: 'NLD',
    label: 'Netherlands',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/2/20/Flag_of_the_Netherlands.svg',
  },
  {
    value: 'NOR',
    label: 'Norway',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/d/d9/Flag_of_Norway.svg',
  },
  {
    value: 'NPL',
    label: 'Nepal',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/9/9b/Flag_of_Nepal.svg',
  },
  {
    value: 'NRU',
    label: 'Nauru',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/3/30/Flag_of_Nauru.svg',
  },
  {
    value: 'NIU',
    label: 'Niue',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/0/01/Flag_of_Niue.svg',
  },
  {
    value: 'NZL',
    label: 'New Zealand',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/3/3e/Flag_of_New_Zealand.svg',
  },
  {
    value: 'OMN',
    label: 'Oman',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/d/dd/Flag_of_Oman.svg',
  },
  {
    value: 'PAN',
    label: 'Panama',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/a/ab/Flag_of_Panama.svg',
  },
  {
    value: 'PER',
    label: 'Peru',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/c/cf/Flag_of_Peru.svg',
  },
  {
    value: 'PYF',
    label: 'French Polynesia',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/d/db/Flag_of_French_Polynesia.svg',
  },
  {
    value: 'PNG',
    label: 'Papua New Guinea',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/e/e3/Flag_of_Papua_New_Guinea.svg',
  },
  {
    value: 'PHL',
    label: 'Philippines',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/9/99/Flag_of_the_Philippines.svg',
  },
  {
    value: 'PAK',
    label: 'Pakistan',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/3/32/Flag_of_Pakistan.svg',
  },
  {
    value: 'POL',
    label: 'Poland',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/1/12/Flag_of_Poland.svg',
  },
  {
    value: 'SPM',
    label: 'Saint Pierre and Miquelon',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/7/74/Flag_of_Saint-Pierre_and_Miquelon.svg',
  },
  {
    value: 'PCN',
    label: 'Pitcairn',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/8/88/Flag_of_the_Pitcairn_Islands.svg',
  },
  {
    value: 'PRI',
    label: 'Puerto Rico',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/2/28/Flag_of_Puerto_Rico.svg',
  },
  {
    value: 'PSE',
    label: 'Palestine, State of',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/f/f4/Palestine_Flag.svg',
  },
  {
    value: 'PRT',
    label: 'Portugal',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/5/5c/Flag_of_Portugal.svg',
  },
  {
    value: 'PLW',
    label: 'Palau',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/4/48/Flag_of_Palau.svg',
  },
  {
    value: 'PRY',
    label: 'Paraguay',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/2/27/Flag_of_Paraguay.svg',
  },
  {
    value: 'QAT',
    label: 'Qatar',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/6/65/Flag_of_Qatar.svg',
  },
  {
    value: 'ROU',
    label: 'Romania',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/7/73/Flag_of_Romania.svg',
  },
  {
    value: 'SRB',
    label: 'Serbia',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/f/ff/Flag_of_Serbia.svg',
  },
  {
    value: 'RUS',
    label: 'Russian Federation',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/f/f3/Flag_of_Russia.svg',
  },
  {
    value: 'RWA',
    label: 'Rwanda',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/1/17/Flag_of_Rwanda.svg',
  },
  {
    value: 'SAU',
    label: 'Saudi Arabia',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/0/0d/Flag_of_Saudi_Arabia.svg',
  },
  {
    value: 'SLB',
    label: 'Solomon Islands',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/7/74/Flag_of_the_Solomon_Islands.svg',
  },
  {
    value: 'SYC',
    label: 'Seychelles',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/f/fc/Flag_of_Seychelles.svg',
  },
  {
    value: 'SDN',
    label: 'Sudan',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/0/01/Flag_of_Sudan.svg',
  },
  {
    value: 'SWE',
    label: 'Sweden',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/4/4c/Flag_of_Sweden.svg',
  },
  {
    value: 'SGP',
    label: 'Singapore',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/4/48/Flag_of_Singapore.svg',
  },
  {
    value: 'SHN',
    label: 'Saint Helena, Ascension and Tristan da Cunha',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/0/00/Flag_of_Saint_Helena.svg',
  },
  {
    value: 'SVN',
    label: 'Slovenia',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/f/f0/Flag_of_Slovenia.svg',
  },
  {
    value: 'SJM',
    label: 'Svalbard and Jan Mayen',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/d/d9/Flag_of_Norway.svg',
  },
  {
    value: 'SVK',
    label: 'Slovakia',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/e/e6/Flag_of_Slovakia.svg',
  },
  {
    value: 'SLE',
    label: 'Sierra Leone',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/1/17/Flag_of_Sierra_Leone.svg',
  },
  {
    value: 'SMR',
    label: 'San Marino',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/b/b1/Flag_of_San_Marino.svg',
  },
  {
    value: 'SEN',
    label: 'Senegal',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/f/fd/Flag_of_Senegal.svg',
  },
  {
    value: 'SOM',
    label: 'Somalia',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/a/a0/Flag_of_Somalia.svg',
  },
  {
    value: 'SUR',
    label: 'Suriname',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/6/60/Flag_of_Suriname.svg',
  },
  {
    value: 'SSD',
    label: 'South Sudan',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/7/7a/Flag_of_South_Sudan.svg',
  },
  {
    value: 'SLV',
    label: 'El Salvador',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/3/34/Flag_of_El_Salvador.svg',
  },
  {
    value: 'SXM',
    label: 'Sint Maarten, (Dutch part)',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/d/d3/Flag_of_Sint_Maarten.svg',
  },
  {
    value: 'SYR',
    label: 'Syrian Arab Republic',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/5/53/Flag_of_Syria.svg',
  },
  {
    value: 'SWZ',
    label: 'Eswatini',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/f/fb/Flag_of_Eswatini.svg',
  },
  {
    value: 'TCA',
    label: 'Turks and Caicos Islands',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/a/a0/Flag_of_the_Turks_and_Caicos_Islands.svg',
  },
  {
    value: 'TCD',
    label: 'Chad',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/4/4b/Flag_of_Chad.svg',
  },
  {
    value: 'TGO',
    label: 'Togo',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/6/68/Flag_of_Togo.svg',
  },
  {
    value: 'THA',
    label: 'Thailand',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/a/a9/Flag_of_Thailand.svg',
  },
  {
    value: 'TJK',
    label: 'Tajikistan',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/d/d0/Flag_of_Tajikistan.svg',
  },
  {
    value: 'TKL',
    label: 'Tokelau',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/8/8e/Flag_of_Tokelau.svg',
  },
  {
    value: 'TLS',
    label: 'Timor-Leste',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/2/26/Flag_of_East_Timor.svg',
  },
  {
    value: 'TKM',
    label: 'Turkmenistan',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/1/1b/Flag_of_Turkmenistan.svg',
  },
  {
    value: 'TUN',
    label: 'Tunisia',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/c/ce/Flag_of_Tunisia.svg',
  },
  {
    value: 'TON',
    label: 'Tonga',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/9/9a/Flag_of_Tonga.svg',
  },
  {
    value: 'TUR',
    label: 'Türkiye',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/b/b4/Flag_of_Turkey.svg',
  },
  {
    value: 'TTO',
    label: 'Trinidad and Tobago',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/6/64/Flag_of_Trinidad_and_Tobago.svg',
  },
  {
    value: 'TUV',
    label: 'Tuvalu',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/3/38/Flag_of_Tuvalu.svg',
  },
  {
    value: 'TWN',
    label: 'Taiwan, Province of China',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/7/72/Flag_of_the_Republic_of_China.svg',
  },
  {
    value: 'TZA',
    label: 'Tanzania, United Republic of',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/3/38/Flag_of_Tanzania.svg',
  },
  {
    value: 'UKR',
    label: 'Ukraine',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/4/49/Flag_of_Ukraine.svg',
  },
  {
    value: 'UGA',
    label: 'Uganda',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/4/4e/Flag_of_Uganda.svg',
  },
  {
    value: 'UMI',
    label: 'United States Minor Outlying Islands',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/0/05/Flag_of_the_U.S..svg',
  },
  {
    value: 'USA',
    label: 'United States of America',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/a/a4/Flag_of_the_United_States.svg',
  },
  {
    value: 'URY',
    label: 'Uruguay',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/f/fe/Flag_of_Uruguay.svg',
  },
  {
    value: 'UZB',
    label: 'Uzbekistan',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/8/84/Flag_of_Uzbekistan.svg',
  },
  {
    value: 'VAT',
    label: 'Holy See',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/0/00/Flag_of_the_Vatican_City.svg',
  },
  {
    value: 'VCT',
    label: 'Saint Vincent and the Grenadines',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/6/6d/Flag_of_Saint_Vincent_and_the_Grenadines.svg',
  },
  {
    value: 'VEN',
    label: 'Venezuela, Bolivarian Republic of',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/7/7b/Flag_of_Venezuela_(state).svg',
  },
  {
    value: 'VGB',
    label: 'Virgin Islands, British',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/4/42/Flag_of_the_British_Virgin_Islands.svg',
  },
  {
    value: 'VIR',
    label: 'Virgin Islands, U.S.',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/f/f8/Flag_of_the_United_States_Virgin_Islands.svg',
  },
  {
    value: 'VNM',
    label: 'Viet Nam',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/2/21/Flag_of_Vietnam.svg',
  },
  {
    value: 'VUT',
    label: 'Vanuatu',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/6/6e/Flag_of_Vanuatu_(official).svg',
  },
  {
    value: 'WLF',
    label: 'Wallis and Futuna',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/d/d2/Flag_of_Wallis_and_Futuna.svg',
  },
  {
    value: 'WSM',
    label: 'Samoa',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/3/31/Flag_of_Samoa.svg',
  },
  {
    value: 'YEM',
    label: 'Yemen',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/8/89/Flag_of_Yemen.svg',
  },
  {
    value: 'MYT',
    label: 'Mayotte',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/c/c3/Flag_of_France.svg',
  },
  {
    value: 'ZAF',
    label: 'South Africa',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/a/af/Flag_of_South_Africa.svg',
  },
  {
    value: 'ZMB',
    label: 'Zambia',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/0/06/Flag_of_Zambia.svg',
  },
  {
    value: 'ZWE',
    label: 'Zimbabwe',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/6/6a/Flag_of_Zimbabwe.svg',
  },
]
