export const gicsDataSet = {
  name: 'Global Industry Classification Standard',
  options: [
    {
      value: '10',
      label: 'Health, Beauty & Wellness',
      children: [
        {
          value: '1010',
          label: 'Beauty & SPAs',
          children: [
            {
              value: '101010',
              label: 'Beauty and Barber Shops',
            },
            {
              value: '101020',
              label: 'Health and Beauty SPAs',
            },
          ],
        },
        {
          value: '1020',
          label: 'Medical & Veterinary',
          children: [
            {
              value: '102010',
              label: 'Ambulance Services',
            },
            {
              value: '102011',
              label: 'Child Care Services',
            },
            {
              value: '102012',
              label: 'Chiropractors',
            },
            {
              value: '102013',
              label: 'Dentists and Orthodontists',
            },
            {
              value: '102014',
              label: 'Doctors - Not Elsewhere Classified',
            },
            {
              value: '102015',
              label: 'Drug stores, Pharmacies',
            },
            {
              value: '102016',
              label: 'Hearing Aids - Sales, Service, and Supply',
            },
            {
              value: '102017',
              label: 'Hospitals',
            },
            {
              value: '102018',
              label: 'Medical and Dental Laboratories',
            },
            {
              value: '102019',
              label:
                'Medical Services and Health Practitioners - Not Elsewhere Classified',
            },
            {
              value: '102020',
              label: 'Nursing and Personal Care Facilities',
            },
            {
              value: '102021',
              label: 'Opticians, Optical Goods, and Eyeglasses',
            },
            {
              value: '102022',
              label: 'Optometrists and Ophthalmologists',
            },
            {
              value: '102023',
              label: 'Orthopedic Goods - Artificial Limb Stores',
            },
            {
              value: '102024',
              label: 'Osteopathic Physicians',
            },
            {
              value: '102025',
              label: 'Podiatrists and Chiropodists',
            },
            {
              value: '102026',
              label: 'Veterinary Services',
            },
          ],
        },
      ],
    },
    {
      value: '20',
      label: 'Hospitality, Food & Beverage',
      children: [
        {
          value: '2010',
          label: 'Bars, Cafes & Restaurants',
          children: [
            {
              value: '201010',
              label: 'Bakeries',
            },
            {
              value: '201011',
              label: 'Caterers',
            },
            {
              value: '201012',
              label: 'Bar, Lounge, Disco, Nightclub, Tavern - Alcoholic drinks',
            },
            {
              value: '201013',
              label: 'Eating Places and Restaurants',
            },
            {
              value: '201014',
              label: 'Fast Food Restaurants',
            },
          ],
        },
        {
          value: '2020',
          label: 'Entertainment, Clubs & Associations',
          children: [
            {
              value: '202010',
              label: 'Agricultural Cooperatives',
            },
            {
              value: '202011',
              label:
                'Amusement Parks, Circuses, Carnivals, and Fortune Tellers',
            },
            {
              value: '202012',
              label: 'Aquariums, Seaquariums, Dolphinariums',
            },
            {
              value: '202013',
              label: 'Automobile Associations',
            },
            {
              value: '202014',
              label:
                'Bands, Orchestras, and Miscellaneous Entertainers - Not Elsewhere Classified',
            },
            {
              value: '202015',
              label: 'Billiard and Pool Establishments',
            },
            {
              value: '202016',
              label: 'Bowling Alleys',
            },
            {
              value: '202017',
              label: 'Charitable and Social Service Organizations',
            },
            {
              value: '202018',
              label: 'Civic, Social, and Fraternal Associations',
            },
            {
              value: '202019',
              label:
                'Commercial Sports, Professional Sports Clubs, Sports Grounds',
            },
            {
              value: '202020',
              label: 'Membership Organizations (Not Elsewhere Classified)',
            },
            {
              value: '202021',
              label: 'Motion Picture / Video Tape Production and Distribution',
            },
            {
              value: '202022',
              label: 'Motion Picture Theaters',
            },
            {
              value: '202023',
              label: 'Political Organizations',
            },
            {
              value: '202024',
              label: 'Public Golf Courses',
            },
            {
              value: '202025',
              label: 'Recreation Services - Not Elsewhere Classified',
            },
            {
              value: '202026',
              label: 'Religious Organizations',
            },
            {
              value: '202027',
              label: 'Swimming Pools  -  Sales and Service',
            },
            {
              value: '202028',
              label: 'Theatrical Producers (except Films) and Ticket Agencies',
            },
            {
              value: '202029',
              label: 'Video Game Arcades/Establishments',
            },
            {
              value: '202030',
              label: 'Wholesale Clubs',
            },
          ],
        },
        {
          value: '2030',
          label: 'Hospitality & Tourism',
          children: [
            {
              value: '203010',
              label: 'Campgrounds and Trailer Parks',
            },
            {
              value: '203011',
              label:
                'Lodging - Hotels, Motels, Resorts, Central Reservation Services',
            },
            {
              value: '203012',
              label: 'Sporting and Recreational Camps',
            },
            {
              value: '203013',
              label: 'Timeshares',
            },
            {
              value: '203014',
              label: 'Tourist Attractions and Exhibits',
            },
          ],
        },
      ],
    },

    {
      value: '30',
      label: 'Rental Services',
      children: [
        {
          value: '3010',
          label: 'Rental',
          children: [
            {
              value: '301010',
              label: 'Europcar',
            },
          ],
        },
        {
          value: '3020',
          label: 'Other Rental Activities',
          children: [
            {
              value: '302010',
              label: 'Boat Rentals and Leasing',
            },
            {
              value: '302011',
              label: 'Clothing Rental  -  Costumes, Uniforms, Formal Wear',
            },
            {
              value: '302012',
              label:
                'Equipment Rental and Leasing Services, Furniture/Tool Rental',
            },
            {
              value: '302013',
              label: 'Motor Home and Recreational Vehicle Rental',
            },
            {
              value: '302014',
              label:
                'Public Warehousing - Farm Products, Refrigerated Goods, Household',
            },
            {
              value: '302015',
              label: 'Real Estate Agents and Managers - Rentals',
            },
            {
              value: '302016',
              label: 'Truck Rental',
            },
            {
              value: '302017',
              label: 'Video Entertainment Rental Stores',
            },
          ],
        },
      ],
    },
    {
      value: '40',
      label: 'Retail',
      children: [
        {
          value: '4010',
          label: 'Apparel',
          children: [
            {
              value: '401010',
              label: 'Accessory and apparel Stores - Miscellaneous',
            },
            {
              value: '401011',
              label: "Children's and Infants' Wear Shops",
            },
            {
              value: '401012',
              label: 'Family Clothing Shops',
            },
            {
              value: '401013',
              label: 'Furriers and Fur Shops',
            },
            {
              value: '401014',
              label: 'Luggage and Leather Goods Shops',
            },
            {
              value: '401015',
              label: "Men's and Boys' Clothing and Accessories Stores",
            },
            {
              value: '401016',
              label: 'Shoe Stores',
            },
            {
              value: '401017',
              label: 'Sports apparel, Riding apparel Stores',
            },
            {
              value: '401018',
              label: 'Tailors, Seamstresses, Mending, and Alterations',
            },
            {
              value: '401019',
              label: 'Wig and Toupee Shops',
            },
            {
              value: '401020',
              label: "Women's Accessory and Specialty Stores",
            },
            {
              value: '401021',
              label: "Women's Ready-to-Wear Stores",
            },
          ],
        },
        {
          value: '4020',
          label: 'Art, Music & Hobby',
          children: [
            {
              value: '402010',
              label: 'Antique Reproductions',
            },
            {
              value: '402011',
              label: 'Art Dealers and Galleries',
            },
            {
              value: '402012',
              label: "Artist's Supply and Craft Shops",
            },
            {
              value: '402013',
              label: 'Book Stores',
            },
            {
              value: '402014',
              label: 'Books, Periodicals and Newspapers',
            },
            {
              value: '402015',
              label: 'Commercial  Art, Graphics, Photography',
            },
            {
              value: '402016',
              label: 'Hobby, Toy, and Game Shops',
            },
            {
              value: '402017',
              label: 'Music Stores - Instruments, Pianos, Sheet Music',
            },
            {
              value: '402018',
              label: 'Record Shops',
            },
            {
              value: '402019',
              label: 'Sewing, Needlework, Fabric and Piece Goods Shops',
            },
            {
              value: '402020',
              label: 'Stamp and Coin Stores - Philatelic and numismatic supply',
            },
          ],
        },
        {
          value: '4030',
          label: 'Convenience Stores & General Merchandise',
          children: [
            {
              value: '403010',
              label: 'Candies, Nuts, and Confectionery Stores',
            },
            { value: '403011', label: 'Dairy Products Stores' },
            { value: '403012', label: 'Department Stores' },
            { value: '403013', label: 'Door-To-Door Sales' },
            { value: '403014', label: 'Florists' },
            { value: '403015', label: 'Freezer, Locker Meat Provisioners' },
            {
              value: '403016',
              label: 'Gift, Card, Novelty and Souvenir Shops',
            },
            { value: '403017', label: 'Miscellaneous General Merchandise' },
            {
              value: '403018',
              label: 'Package Stores - Beer, Wine, Liquor',
            },
            { value: '403019', label: 'Pet Shops - Pet Foods and Supplies' },
            {
              value: '403020',
              label: 'Piece Goods, Notions, and other Dry Goods',
            },
            { value: '403021', label: 'Used Merchandise and Secondhand Shops' },
            { value: '403022', label: 'Variety Stores' },
          ],
        },
        {
          value: '4040',
          label: 'Electronic, Equipment & Supplies',
          children: [
            {
              value: '404010',
              label: 'Bicycle Shops  -  Sales and Service',
            },
            { value: '404011', label: 'Camera and Photographic Supply Stores' },
            {
              value: '404012',
              label: 'Computers and Computer Peripheral Equipment and Software',
            },
            {
              value: '404013',
              label: 'Electric Razor Stores  -  Sales and Service',
            },
            { value: '404014', label: 'Electrical Parts and Equipment' },
            { value: '404015', label: 'Electronic Sales' },
            { value: '404016', label: 'Hardware Stores' },
            {
              value: '404017',
              label: 'Plumbing and Heating Equipment and Supplies',
            },
            { value: '404018', label: 'Sporting Goods Stores' },
            {
              value: '404019',
              label: 'Telecommunication Equipment and Telephone Sales',
            },
            {
              value: '404020',
              label: 'Typewriter Shops - Sales, Rentals, Service',
            },
            { value: '404021', label: 'Video Amusement Game Supplies' },
          ],
        },
        {
          value: '4050',
          label: 'Home & Office',
          children: [
            {
              value: '405010',
              label: 'Antique Shops - Sales, Repairs, and Restoration Services',
            },
            { value: '405011', label: 'Crystal and Glassware Stores' },
            {
              value: '405012',
              label: 'Drapery, Upholstery, and Window Coverings Stores',
            },
            {
              value: '405013',
              label: 'Fireplace, Fireplace Screens and Accessories Stores',
            },
            { value: '405014', label: 'Floor Covering Stores' },
            {
              value: '405015',
              label:
                'Furniture, Home Furnishings, and Equipment Shops, Except Appliances',
            },
            { value: '405016', label: 'Glass, Paint, Wallpaper Stores' },
            { value: '405017', label: 'Home Supply Warehouse Stores' },
            { value: '405018', label: 'Household Appliance Stores' },
            { value: '405019', label: 'Lawn and Garden Supply Stores' },
            {
              value: '405020',
              label: 'Miscellaneous Home Furnishing Specialty Stores',
            },
            {
              value: '405021',
              label: 'Office, School Supply, and Stationery Stores',
            },
          ],
        },
        {
          value: '4060',
          label: 'Speciality',
          children: [
            {
              value: '406010',
              label:
                'Auto and Truck Dealers - Sales, Service, Repairs, Parts, and Leasing',
            },
            {
              value: '406011',
              label: 'Auto and Truck Dealers (Used Only) - Sales',
            },
            { value: '406012', label: 'Automotive Tire Dealers' },
            { value: '406013', label: 'Building Materials, Lumber Stores' },
            {
              value: '406014',
              label: 'Buying and Shopping Services and Clubs',
            },
            { value: '406015', label: 'Cigar Stores and Stands' },
            {
              value: '406016',
              label: 'Clock, Jewelry, Watch, and Silverware Stores',
            },
            { value: '406017', label: 'Computer Software Stores' },
            {
              value: '406018',
              label: 'Construction Materials - Not Elsewhere Classified',
            },
            { value: '406019', label: 'Cosmetics Stores' },
            { value: '406020', label: 'Discount Stores' },
            { value: '406021', label: 'Duty Free Stores' },
            {
              value: '406022',
              label: 'Miscellaneous and Specialty Retail Shops',
            },
            { value: '406023', label: 'Newsagents and Newsstands' },
            { value: '406024', label: 'Pawn Shops' },
            { value: '406025', label: 'Religious Goods Shops' },
            { value: '406026', label: 'Tent and Awning Shops' },
          ],
        },
      ],
    },

    {
      value: '50',
      label: 'Services',
      children: [
        {
          value: '5010',
          label: 'Automobile related',
          children: [
            { value: '501010', label: 'Automobile Parking Lots and Garages' },
            { value: '501011', label: 'Automotive Body Repair Shops' },
            { value: '501012', label: 'Automotive Paint Shops' },
            {
              value: '501013',
              label: 'Automotive Parts and Accessories Shops',
            },
            { value: '501014', label: 'Automotive Service Shops (Non-Dealer)' },
            { value: '501015', label: 'Car Washes' },
            {
              value: '501016',
              label:
                'Gas and Petroleum Stations (with or without Assistance Services)',
            },
            { value: '501017', label: 'Tire Retreading and Repair Shops' },
            { value: '501018', label: 'Towing Services' },
          ],
        },
        {
          value: '5020',
          label: 'Cleaning services',
          children: [
            { value: '502010', label: 'Carpet and Upholstery Cleaning' },
            {
              value: '502011',
              label: 'Cleaning and Maintenance, Janitorial Services',
            },
            {
              value: '502012',
              label: 'Cleaning, Garment and Laundry Services',
            },
            { value: '502013', label: 'Dry Cleaners' },
            {
              value: '502014',
              label: 'Exterminating and Disinfecting Services',
            },
            { value: '502015', label: 'Laundries - Family and Commercial' },
            {
              value: '502016',
              label: 'Sanitation, Polishing & Speciality Cleaning Prep',
            },
          ],
        },
        {
          value: '5030',
          label: 'Contractor & Craftsman',
          children: [
            { value: '503010', label: 'Carpentry Contractors' },
            { value: '503011', label: 'Concrete Work Contractors' },
            { value: '503012', label: 'Electrical Contractors' },
            {
              value: '503013',
              label: 'General Contractors - Residential Buildings',
            },
            {
              value: '503014',
              label: 'Heating, Plumbing, and Air Conditioning Contractors',
            },
            {
              value: '503015',
              label:
                'Insulation, Masonry, Plastering, Stonework, Tileset Contractors',
            },
            {
              value: '503016',
              label: 'Landscaping and Horticultural Services',
            },
            {
              value: '503017',
              label: 'Roofing, Siding, and Sheet Metal Work Contractors',
            },
            {
              value: '503018',
              label: 'Special Trade Contractors (Not Elsewhere Classified)',
            },
            { value: '503019', label: 'Welding Services' },
            { value: '503020', label: 'Wrecking and Salvage Yards' },
          ],
        },
        {
          value: '5040',
          label: 'Education & Government related',
          children: [
            { value: '504010', label: 'Business and Secretarial Schools' },
            {
              value: '504011',
              label:
                'Colleges, Universities, Professional Schools, and Junior Colleges',
            },
            { value: '504012', label: 'Dance Halls, Studios and Schools' },
            { value: '504013', label: 'Distance Learning Schools' },
            { value: '504014', label: 'Elementary and Secondary Schools' },
            {
              value: '504015',
              label: 'Government Services, Not Else Classified',
            },
            {
              value: '504016',
              label: 'Intra-Government Purchases - Governement Only',
            },
            { value: '504017', label: 'Postal Services - Government Only' },
            {
              value: '504018',
              label:
                'Schools and Educational Services (Not Elsewhere Classified)',
            },
            { value: '504019', label: 'Vocational and Trade Schools' },
          ],
        },
        {
          value: '5050',
          label: 'Legal, Finance & Insurance',
          children: [
            {
              value: '505010',
              label: 'Accounting, Auditing, and Bookkeeping Services',
            },
            {
              value: '505011',
              label:
                'Automated Cash Disbursements - Customer Financial Institution',
            },
            { value: '505012', label: 'Bail and Bond Payments' },
            { value: '505013', label: 'Consumer Credit Reporting Agencies' },
            {
              value: '505014',
              label: 'Court Costs, including Alimony and Child Support',
            },
            { value: '505015', label: 'Fines' },
            {
              value: '505016',
              label: 'Insurance Sales, Underwriting, and Premiums',
            },
            { value: '505017', label: 'Legal Services and Attorneys' },
            {
              value: '505018',
              label:
                'Manual Cash Disbursements - Customer Financial Institution',
            },
            {
              value: '505019',
              label:
                'Merchandise and Services - Customer Financial Institution',
            },
            { value: '505020', label: 'Money Transfer' },
            {
              value: '505021',
              label: 'Stenographic and Secretarial Support Services',
            },
            { value: '505022', label: 'Tax Payments' },
            { value: '505023', label: 'Tax Preparation Services' },
          ],
        },
        {
          value: '5060',
          label: 'Professional & Consulting services',
          children: [
            { value: '506010', label: 'Advertising Services' },
            {
              value: '506011',
              label: 'Architectural, Engineering, and Surveying Services',
            },
            {
              value: '506012',
              label: 'Business Services - Not elsewhere classified',
            },
            {
              value: '506013',
              label: 'Cable, Satellite, Other Pay Television, Radio Services',
            },
            { value: '506014', label: 'Computer Network/Information Services' },
            {
              value: '506015',
              label:
                'Computer Programming, Data Processing, and Integrated Systems Design Services',
            },
            {
              value: '506016',
              label: 'Counseling Services - Debt, Marriage, and Personal',
            },
            {
              value: '506017',
              label: 'Employment Agencies and Temporary Help Services',
            },
            { value: '506018', label: 'Funeral Services and Crematoria' },
            { value: '506019', label: 'Information Retrieval Services' },
            {
              value: '506020',
              label: 'Management, Consulting, and Public Relations Services',
            },
            { value: '506021', label: 'Miscellaneous Publishing and Printing' },
            {
              value: '506022',
              label: 'Other Services - Not Elsewhere Classified',
            },
            {
              value: '506023',
              label: 'Photofinishing Laboratories and Photo Developing',
            },
            { value: '506024', label: 'Photographic Studios' },
            {
              value: '506025',
              label: 'Professional Services (Not Elsewhere Classified)',
            },
            {
              value: '506026',
              label: 'Quick Copy, Reproduction, and Blueprinting Services',
            },
            {
              value: '506027',
              label:
                'Telecommunication including Prepaid/Recurring Phone Services',
            },
            { value: '506028', label: 'Telegraph Services' },
            {
              value: '506029',
              label: 'Testing Laboratories (Non-Medical Testing)',
            },
            {
              value: '506030',
              label: 'Utilities - Electric, Gas, Heating Oil, Sanitary, Water',
            },
          ],
        },
        {
          value: '5070',
          label: 'Repairs & Support',
          children: [
            {
              value: '507010',
              label: 'Air Conditioning and Refrigeration Repair Shops',
            },
            {
              value: '507011',
              label:
                'Computer Maintenance, Repair and Services - Not Elsewhere Classified',
            },
            {
              value: '507012',
              label: 'Electrical and Small Appliance Repair Shops',
            },
            { value: '507013', label: 'Electronics Repair Shops' },
            {
              value: '507014',
              label: 'Furniture Reupholstery, Repair, and Refinishing',
            },
            {
              value: '507015',
              label: 'Miscellaneous Repair Shops and Related Services',
            },
            {
              value: '507016',
              label:
                'Shoe Repair Shops, Shoe Shine Parlors, and Hat Cleaning Shops',
            },
            { value: '507017', label: 'Watch, Clock and Jewelry Repair' },
          ],
        },
        {
          value: '5080',
          label: 'Transportation',
          children: [
            {
              value: '508010',
              label: 'Airports, Airfields, and Airport Terminals',
            },
            { value: '508011', label: 'Bridge and Road Fees, Tolls' },
            { value: '508012', label: 'Bus Lines' },
            {
              value: '508013',
              label:
                'Courier Services - Air and Ground, and Freight Forwarders',
            },
            {
              value: '508014',
              label:
                'Freight Carrier, Trucking - Local/Long Distance, Moving/Storage',
            },
            { value: '508015', label: 'Limousines and Taxicabs' },
            { value: '508016', label: 'Marinas, Marine Service, and Supplies' },
            { value: '508017', label: 'Passenger Railways' },
            { value: '508018', label: 'Railroads - Freight' },
            {
              value: '508019',
              label:
                'Transportation - Local and Suburban Commuter Passenger, including Ferries',
            },
            {
              value: '508020',
              label: 'Transportation Services (Not Elsewhere Classified)',
            },
          ],
        },
      ],
    },

    {
      value: '60',
      label: 'Wholesale',
      children: [
        {
          value: '6010',
          label: 'Commercial',
          children: [
            { value: '601010', label: 'Boat Dealers' },
            {
              value: '601011',
              label: 'Camper Dealers, Recreational and Utility Trailers',
            },
            {
              value: '601012',
              label: 'Commercial Equipment - Not Elsewhere Classified',
            },
            { value: '601013', label: 'Commercial Footwear' },
            {
              value: '601014',
              label: "Drugs, Drug Proprietaries, and Druggist's Supplies",
            },
            {
              value: '601015',
              label: 'Florists Supplies, Nursery Stock and Flowers',
            },
            {
              value: '601016',
              label:
                "	Men's, Women's, Children's Uniforms and Commercial Clothing	",
            },
            { value: '601017', label: 'Mobile Home Dealers' },
            { value: '601018', label: 'Motor Home Dealers' },
            { value: '601019', label: 'Motor Vehicle Supplies and New Parts' },
            { value: '601020', label: 'Motorcycle Shops and Dealers' },
            {
              value: '601021',
              label: 'Non-Durable Goods - Not Elsewhere Classified',
            },
            { value: '601022', label: 'Office and Commercial Furniture' },
            {
              value: '601023',
              label: 'Office Photographic Photocopy and Microfilm Equipment',
            },
            {
              value: '601024',
              label: 'Precious Stones and Metals, Watches and Jewelry',
            },
            { value: '601025', label: 'Snowmobile Dealers' },
            {
              value: '601026',
              label: 'Stationery, Office Supplies, Printing and Writing Paper',
            },
            {
              value: '601027',
              label: 'Typesetting, Plate Making and Related Services',
            },
          ],
        },
        {
          value: '6020',
          label: 'Industrial',
          children: [
            {
              value: '602010',
              label: 'Chemicals/Allied Products - Not Elsewhere Classified',
            },
            {
              value: '602011',
              label: 'Durable Goods - Not Elsewhere Classified',
            },
            {
              value: '602012',
              label: 'Electric Vehicle Charging for global use',
            },
            {
              value: '602013',
              label:
                'Fuel Dealers - Fuel Oil, Wood, Coal, and Liquefied Petroleum',
            },
            { value: '602014', label: 'Fuel Dispenser, Automated' },
            { value: '602015', label: 'Hardware, Equipment and Supplies' },
            {
              value: '602016',
              label: 'Industrial Supplies - Not Elsewhere Classified',
            },
            {
              value: '602017',
              label:
                'Medical, Dental, Ophthalmic and Hospital Equipment and Supplies',
            },
            { value: '602018', label: 'Metal Service Centers and Offices' },
            {
              value: '602019',
              label:
                'Miscellaneous Automotive, Aircraft, and Farm Equipment Dealers - Not Elsewhere Classified',
            },
            { value: '602020', label: 'Paints, Varnishes and Supplies' },
            { value: '602021', label: 'Petroleum and Petroleum Products' },
          ],
        },
      ],
    },
  ],
}

gicsDataSet.options.reduce((acc, item) => {
  acc.push({ value: item.value, label: item.label })
  return acc
}, [])
