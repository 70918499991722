import styled from 'styled-components'

export const Page = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`

export const FullScreen = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
`

interface ColumnProps {
  background?: string
  first?: boolean
}

export const Column = styled.div<ColumnProps>`
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  background: ${(props) => (props.background ? props.background : '#FFFFFF')};

  @media (max-width: 980px) {
    display: ${(props) => (props.first ? 'none' : 'flex')};
    width: 100%;
  }
`

interface ColumnContentProps {
  marginTop?: string
}

export const ColumnContent = styled.div<ColumnContentProps>`
  width: 80%;
  margin-top: ${(props) => (props.marginTop ? props.marginTop : '0')};
`

interface ColumnHeading {
  margin?: string
  fontSize?: string
}

export const ColumnHeading = styled.div<ColumnHeading>`
  margin: ${(props) => (props.margin ? props.margin : '84px 0 8px 0')};
  color: ${(props) => (props.color ? props.color : '#000000')};
  font-size: ${(props) => (props.fontSize ? props.fontSize : '1em')};
`

interface ColumnSubtitleProps {
  marginBottom?: string
}

export const ColumnSubtitle = styled.div<ColumnSubtitleProps>`
  margin: 0;
  margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : '0')};
`

export const ColumnFlexLine = styled.div`
  width: 100%;
  display: flex;
`

export const NoShow = styled.div`
  opacity: 0;
`

// POS Page

interface PosColumnContentProps {
  marginTop?: string
  justifyContent?: string
}

export const PosColumnContent = styled.div<PosColumnContentProps>`
  width: 80%;
  margin-top: ${(props) => (props.marginTop ? props.marginTop : '0')};

  display: flex;
  flex-direction: column;
  justify-content: ${(props) =>
    props.justifyContent ? props.justifyContent : 'space-between'};
  align-items: center;
  height: calc(100% - 120px);
`

export const PosLogoWrapper = styled.div`
  width: 100%;
`

export const PosPhoneBanner = styled.img`
  /* width: 358px; */
`

export const PosHeader = styled.h1`
  color: #fff;
  font-family: Inter;
  font-size: 35px;
  font-style: normal;
  font-weight: 700;
  line-height: 25px; /* 71.429% */
`

export const PosSubtitle = styled.p`
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  line-height: 165%; /* 41.25px */
`

export const AppDownloadGrid = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 60px;
`

export const PosLoginHeader = styled.h1`
  color: #3e4043;
  text-align: center;
  font-family: Inter;
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
  line-height: 25px;
`

export const PosLoginSubtitle = styled.p`
  color: #3e4043;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 25px;
  margin-bottom: 60px;
`
