import styled from 'styled-components'

interface ContainerProps {
  margin?: string
  color?: string
  colorActive?: string
  border?: string
  fontFamily?: string
}

export const Container = styled.ul<ContainerProps>`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: ${(props) => (props.margin ? props.margin : '0')};
  padding: 0;
  font-family: ${(props) => (props.fontFamily ? props.fontFamily : 'Inter')};

  li {
    width: 100%;
    list-style: none;

    a {
      display: inline-block;
      width: 100%;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.16px;
      text-decoration: none;
      box-sizing: border-box;
      padding: 8px 16px 8px 46px;
      color: ${(props) => (props.color ? props.color : '#272142')};
      font-weight: 400;

      &.active {
        border-left: ${(props) => (props.border ? props.border : 'none')};
        padding-left: 40px;
        color: ${(props) =>
          props.colorActive ? props.colorActive : '#7d51da'};
      }
    }
  }
`
