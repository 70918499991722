import { TopBar as TopBarContainer } from '../../components/TopBar/TopBar'
import axios from 'axios'
import { observer } from 'mobx-react'
import React, { useContext } from 'react'
import { toast } from 'react-toastify'

import { StoreContext } from '../App'
import { UserIcon } from '../Icons/User'
import { SignicatLogo } from '../SignicatLogo/SignicatLogo'

import { CheckoutLogo } from '../CheckoutLogo/CheckoutLogo'
import { PosLogo } from '../PosLogo/PosLogo'
import { CallLogout, TopContent } from './TopBar.styles'

export const TopBar = observer(() => {
  const store = useContext(StoreContext)
  const { theme } = store.AppState

  async function handleLogout() {
    try {
      const res = await axios(
        'https://merchant-poc-2577551f9ce2.herokuapp.com/auth/signout',
        {
          method: 'GET',
          withCredentials: true,
        }
      )

      // store.Router.setLocation('login')
      toast('Logged out', { type: 'success' })
    } catch (e) {
      console.log(e)
      toast('Something went wrong', { type: 'error' })
      // store.Router.setLocation('login')
    }
  }

  const switchLogo = (name: string) => {
    switch (name) {
      case 'pos':
        return (
          <PosLogo
            width="38px"
            color="#fff"
            bgColor="#2A0062"
            padding="16px 40px 16px 32px"
          />
        )
      case 'checkout':
        return (
          <CheckoutLogo
            width="149px"
            color="#fff"
            bgColor="#2A0062"
            padding="16px 40px 16px 32px"
          />
        )
      default:
        return (
          <SignicatLogo
            width="59.676px"
            color="#fff"
            bgColor="#2A0062"
            padding="16px 40px 16px 32px"
          />
        )
    }
  }
  // TODO:
  // trigged logout on userIcon click
  return (
    <TopBarContainer {...theme.topBar}>
      <TopContent>{switchLogo(theme.globals.name)}</TopContent>
      <TopContent>
        <CallLogout onClick={() => handleLogout()}>
          <UserIcon size="32px" color="#fff" margin="14px 32px 18px 24px" />
        </CallLogout>
      </TopContent>
    </TopBarContainer>
  )
})
