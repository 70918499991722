import React, { useContext, useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { StoreContext } from '../../../../components/App'
import { PersonCard } from '../../../../components/PersonCard/PersonCard'
import { ToRight } from '../../EnrollCompany.styles'
import { Boxed, Container, ProceedButton } from './../enroll.styles'
import axios from 'axios'
import { Actor } from '../Directors'

interface DirectorsProps {
  initialEdit: boolean
}

export const Directors = observer(({ initialEdit }: DirectorsProps) => {
  const store = useContext(StoreContext)
  const { theme } = store.AppState
  const {
    enrollCompanyPage: { directors: trans },
  } = store.TranslationsState.translations

  const [edit, setEdit] = useState(initialEdit)
  const [isDisabled, setIsDisabled] = useState(false)
  const [isPolling, setIsPolling] = useState(true)

  const [people, setPeople] = useState<Actor[]>([])

  const handleChangePerson = (actor: Actor) => {
    console.log(actor)

    setPeople((prevItems) =>
      prevItems.map((person) =>
        person.actorId === actor.actorId ? actor : person
      )
    )
  }

  const handleResendEmail = async (id: string) => {
    await axios.post(
      `${process.env.WEB_API_URL}/merchant-enrolment/resend-email`,
      { actorId: id },
      { withCredentials: true }
    )
  }

  const handleProceed = () => {
    setIsDisabled(true)
    setEdit(false)
    console.log(people)
  }

  const getActors = async () => {
    try {
      setIsDisabled(true)
      const res = await axios.get(
        `${process.env.WEB_API_URL}/merchant-enrolment/actors`,
        { withCredentials: true }
      )

      if (res.data) {
        console.log(res.data)

        setPeople(
          res.data.actors.sort((a, b) =>
            a.actorId.localeCompare(b.actorId)
          ) as Actor[]
        )

        if (
          res.data.actors
            .filter((actor: Actor) => !actor.isApplicant && actor.isDirector)
            .every((actor: Actor) => actor.isVerified)
        ) {
          setIsPolling(false)
        }
      }

      setIsDisabled(false)
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    getActors()
  }, [])

  useEffect(() => {
    if (isPolling) {
      const intervalId = setInterval(getActors, 30000) // Poll every 30 seconds

      // Cleanup on unmount or when stopping polling
      return () => clearInterval(intervalId)
    }
  }, [isPolling])

  return (
    <Container>
      <Boxed>
        {people
          .filter((person) => person.isDirector)
          .map((person) => (
            <PersonCard
              key={person.actorId}
              person={person}
              onChange={handleChangePerson}
              onEdit={() => setEdit(true)}
              initialEdit={edit}
              isLoading={isDisabled}
              resendEmail={handleResendEmail}
            />
          ))}

        {edit && (
          <ToRight>
            <ProceedButton
              onClick={handleProceed}
              disabled={isDisabled}
              {...theme.proceedButton}
            >
              <span>{trans.save}</span>
            </ProceedButton>
          </ToRight>
        )}
      </Boxed>
    </Container>
  )
})
