import styled, { css, keyframes } from 'styled-components'

const fadeIn = keyframes`
  0% {
    display: none;
    opacity: 0;
  }
  1% {
    display: block;
    opacity: 0;
  }
  100% {
    display: block;
    opacity: 1;
  }
`

const slideInFromRight = keyframes`
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
`

const slideInFromLeft = keyframes`
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
`

interface WrapProps {
  side?: string
}

interface ContainerProps {
  visible?: boolean
  side?: string
}

export const Wrap = styled.div<WrapProps>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: ${(props) => (props.side ? props.side : 'right')};
  z-index: 100;
  pointer-events: none;
`

export const Overlay = styled.div<ContainerProps>`
  transition: all 0.5s ease-out;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  ${({ visible }) =>
    visible &&
    css`
      position: absolute;
      width: 100%;
      height: 100%;
      background: rgba(7, 0, 38, 0.3);
      z-index: 9;
      pointer-events: auto;
    `};
`

export const Container = styled.div<ContainerProps>`
  position: fixed;
  width: auto;
  height: 100vh;
  background-color: #fff;
  box-shadow: rgba(94, 94, 94, 0.25) -9px 0px 15px 0px;
  box-sizing: border-box;
  padding: 40px;
  overflow: hidden auto;
  pointer-events: auto;
  z-index: 10;
  transition: all 0.5s ease-out;
  overflow: scroll;

  ${({ side }) =>
    side === 'left'
      ? css`
          transform: translateX(-100%);
        `
      : css`
          transform: translateX(100%);
        `};

  ${({ visible }) =>
    visible &&
    css`
      transform: translateX(0%);
    `};
`
