import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  min-width: 550px;

  @media (max-width: 980px) {
    min-width: 90%;
  }
`

export const InputWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  font-family: 'Inter';

  label,
  input {
    width: 100%;
  }

  label {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    color: #1c1c1c;
  }

  input[type='text'] {
    border-radius: 4px;
    border: 1px solid #c6c3c3;
    background: #fff;
    font-size: 14px;
    padding: 10px 15px;
    color: #272142;

    &:focus {
      outline: 1px solid #0071f3;
    }
  }

  input[type='checkbox'],
  input[type='radio'] {
    display: inline-block;
  }

  input[type='radio'] {
    width: auto;
  }
`

export const InputInfo = styled.div`
  font-family: 'Inter';
  font-size: 12px;
  margin-top: 6px;
  color: rgb(218 81 81);
`

export const FormBottom = styled.div`
  width: 100%;
  margin-top: 20px;
`
